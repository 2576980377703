import { createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';
import {axiosInstance} from '../../utils/axiosInstance';
import setAuthToken from '../../utils/setAuthToken';

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('tenant');
  dispatch(logout());
};

export const loadUser = createAsyncThunk(
  'auth/loadUser',
  async (_, { rejectWithValue }) => {
    if (localStorage.token && localStorage.tenant) {
      setAuthToken(localStorage.token, localStorage.tenant);
    }

    try {
      const response = await axiosInstance.get('/v1/tenant/user');
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async (formData, { rejectWithValue, dispatch }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await axiosInstance.post('/v1/tenant/user/signup', formData, config);
      return response.data;
    } catch (error) {
      setTimeout(() => {
        dispatch({
          type: 'auth/clearErrors'
        });
      }, 4000);
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);


export const login = createAsyncThunk(
  'auth/login',
  async (formData, { rejectWithValue, dispatch }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await axiosInstance.post('/v1/admin/auth', formData, config);
      const { jwtToken, authUser } = response.data;
      localStorage.setItem('token', jwtToken);
      localStorage.setItem('tenant', authUser.tenant);
      localStorage.setItem('bannerDismissed', 'false');
      
      return response.data;
    } catch (error) {
      setTimeout(() => {
        dispatch({
          type: 'auth/clearErrors'
        });
      }, 4000);
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);


export const updateEmail = createAsyncThunk(
  'auth/updateEmail',
  async (user, { rejectWithValue, dispatch }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await axiosInstance.put(
        `/v1/tenant/user/update/email/${user.id}`,
        user,
        config
      )
      
      return response.data;
    } catch (error) {
      setTimeout(() => {
        dispatch({
          type: 'auth/clearErrors'
        });
      }, 4000);
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);


export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async ({user_id, passwords}, { rejectWithValue, dispatch }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await axiosInstance.put(
        `/v1/tenant/user/update/password/${user_id}`,
        passwords,
        config
      )
      
      return response.data
    } catch (error) {
      setTimeout(() => {
        dispatch({
          type: 'auth/clearErrors'
        });
      }, 4000);
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);


export const updateUserName = createAsyncThunk(
  'auth/updateUserName',
  async (user, { rejectWithValue, dispatch }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await axiosInstance.put(`/v1/tenant/user/${user.id}`, user, config)
      
      return response.data
    } catch (error) {
      setTimeout(() => {
        dispatch({
          type: 'auth/clearErrors'
        });
      }, 4000);
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);


export const deleteAccount = createAsyncThunk(
  'auth/deleteAccount',
  async ({id, password}, { rejectWithValue, dispatch }) => {
    const config = {
      headers: {
        password
      }
    }

    try {
      await axiosInstance.delete(`/v1/tenant/user/${id}`, config)
      
      return
    } catch (error) {
      setTimeout(() => {
        dispatch({
          type: 'auth/clearErrors'
        });
      }, 4000);
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);


export const getOtp = createAsyncThunk(
  'auth/getOtp',
  async (data, { rejectWithValue }) => {
   

    try {
      const response = await axiosInstance.post(`/v1/admin/otp`,data)
      
      return response.data
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);

export const verifyOtp = createAsyncThunk(
  'auth/verifyOtp',
  async (data, { rejectWithValue }) => {
   

    try {
      const response = await axiosInstance.post(`/v1/admin/verify-otp`,data)
      
      return response.data
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);

export const getInverterFields = createAsyncThunk(
  'auth/getInverterFields',
  async (_, { rejectWithValue }) => {
   

    try {
      const response = await axiosInstance.get(`/v1/admin/inverter-fields`)
      
      return response.data
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);

export const installerSignup = createAsyncThunk(
  'auth/installerSignup',
  async (data, { rejectWithValue }) => {
   

    try {
      const response = await axiosInstance.post(`/v1/admin/signUp`,data)
      
      return response.data
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);