import { createSlice } from '@reduxjs/toolkit'
import { addCustomerDetails, deleteUser, getCities, getCustomerDetails, getCustomersWithEmail, getFEs, getPostalCodes, getSites, getStates, getTechs, getUserActivity, getUsers, updateCustomerDetails, updateUserData } from './userThunks'

const initialState = {
  techs: null,
  users: null,
  error: null,
  userLoading: false,
  userData: null,
  userInfoUpdateLoading: false,
  userInfoUpdateError: null,
  userUpdateMessage: '',
  userUpdateStatus: '',
  userDeleteMessage: '',
  userDeleteStatus: '',
  statesDataLoading: false,
  citiesDataLoading: false,
  sitesDataLoading: false,
  postalCodeDataLoading: false,
  statesData: null,
  citiesData: null,
  sitesData: null,
  postalCodeData: null,
  customerDetailsLoading: false,
  customerDetails: null,
  currentCustomerData: null,
  updatingCustomerData: false,
  customerDataUpdated: null,
  totalCustomerPages: 1,
  customersWithEmail: null,
  addingCustomerData: false,
  customerDataAdded: null
}

const resetStateOnFailure = (state) => {
  state.statesDataLoading = false;
  state.citiesDataLoading = false;
  state.sitesDataLoading = false;
  state.postalCodeDataLoading = false;
  state.statesData = null;
  state.citiesData = null;
  state.sitesData = null;
  state.postalCodeData = null;
  state.customerDetailsLoading = false;
  state.customerDetails = null;
  state.currentCustomerData = null;
  state.updatingCustomerData = false;
  state.customerDataUpdated = null;
  state.addingCustomerData = false;
  state.customerDataAdded = null;
};


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserState() {
      return initialState
    },
    setUserData(state, action) {
      state.userData = action.payload
    },
    resetUserData(state) {
      state.userData = null
    },
    clearUpdateMessages(state) {
      state.userInfoUpdateLoading = false
      state.userUpdateMessage = ''
      state.userUpdateStatus = ''
    },
    clearDeleteMessages(state) {
      state.userDeleteMessage = ''
      state.userDeleteStatus = ''
    },
    setCurrentCustomer(state, action) {
      state.currentCustomerData = action.payload
    },
    clearCurrentCustomer(state) {
      state.currentCustomerData = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getTechs.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getTechs.fulfilled, (state, action) => {
        state.techs = action.payload
        state.userLoading = false
      })
      .addCase(getTechs.rejected, (state, action) => {
        state.error = action.payload
        state.userLoading = false
        resetStateOnFailure(state);
      })
      .addCase(getFEs.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getFEs.fulfilled, (state, action) => {
        state.fEngs = action.payload
        state.userLoading = false
      })
      .addCase(getFEs.rejected, (state, action) => {
        state.error = action.payload
        state.userLoading = false
        resetStateOnFailure(state);
      })
      .addCase(getUsers.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload
        state.userLoading = false
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.error = action.payload
        state.userLoading = false
        resetStateOnFailure(state);
      })
      .addCase(updateUserData.pending, (state) => {
        state.userInfoUpdateLoading = true;
        state.userUpdateStatus = ''
      })
      .addCase(updateUserData.fulfilled, (state, action) => {
        state.userInfoUpdateLoading = false
        state.userUpdateStatus = action.payload.status
        state.userUpdateMessage = action.payload.msg
      })
      .addCase(updateUserData.rejected, (state, action) => {
        state.userInfoUpdateLoading = false
        state.userInfoUpdateError = action.payload
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.userDeleteMessage = action.payload.msg
        state.userDeleteStatus = action.payload.status
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state);
      })
      .addCase(getStates.pending, (state) => {
        state.statesDataLoading = true;
      })
      .addCase(getStates.fulfilled, (state, action) => {
        state.statesData = action.payload
        state.statesDataLoading = false
      })
      .addCase(getStates.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state);
      })
      .addCase(getCities.pending, (state) => {
        state.citiesDataLoading = true;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.citiesData = action.payload
        state.citiesDataLoading = false
      })
      .addCase(getCities.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state);
      })
      .addCase(getPostalCodes.pending, (state) => {
        state.postalCodeDataLoading = true;
      })
      .addCase(getPostalCodes.fulfilled, (state, action) => {
        state.postalCodeData = action.payload
        state.postalCodeDataLoading = false
      })
      .addCase(getPostalCodes.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state);
      })
      .addCase(getSites.pending, (state) => {
        state.sitesDataLoading = true;
      })
      .addCase(getSites.fulfilled, (state, action) => {
        state.sitesData = action.payload
        state.sitesDataLoading = false
      })
      .addCase(getSites.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state);
      })
      // .addCase(getUserActivity.fulfilled, (state, action) => {
      //     state.sitesData = action.payload,
      //     state.sitesDataLoading = false
      // })
      .addCase(getUserActivity.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state);
      })
      .addCase(getCustomerDetails.pending, (state, action) => {
        state.customerDetailsLoading = true
        state.customerDetails = null
      })
      .addCase(getCustomerDetails.fulfilled, (state, action) => {
        state.customerDetailsLoading = false
        state.customerDetails = action.payload.details
        state.totalCustomerPages = action.payload.totalPages
      })
      .addCase(getCustomerDetails.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state);
      })
      .addCase(updateCustomerDetails.pending, (state) => {
        state.updatingCustomerData = true
        state.customerDataUpdated = null
      })
      .addCase(updateCustomerDetails.fulfilled, (state, action) => {
        state.updatingCustomerData = false
        state.customerDataUpdated = action.payload
      })
      .addCase(updateCustomerDetails.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state);
      })
      .addCase(getCustomersWithEmail.fulfilled, (state, action) => {
        state.customersWithEmail = action.payload
      })
      .addCase(getCustomersWithEmail.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state);
      })
      .addCase(addCustomerDetails.pending, (state) => {
        state.addingCustomerData = true
        state.customerDataAdded = null
      })
      .addCase(addCustomerDetails.fulfilled, (state, action) => {
        state.addingCustomerData = false
        state.customerDataAdded = action.payload

      })
      .addCase(addCustomerDetails.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state);
      })

  }
})

export const { resetUserState, setUserData, resetUserData, clearUpdateMessages, clearDeleteMessages, setCurrentCustomer, clearCurrentCustomer } = userSlice.actions
export default userSlice.reducer