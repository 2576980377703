import { createSlice } from '@reduxjs/toolkit'
import { 
  getTicket, 
  getSiteNames, 
  getTickets, 
  addTicket, 
  deleteTicket, 
  updateTicket, 
  updateNameOnTickets, 
  sendMail, 
  uploadMaintenanceImages, 
  getTechsAndFEs, 
  getFilteredTickets, 
  downloadMaintenanceReport, 
  previewMaintenanceReport, 
  mailMaintenanceReport, 
  saveMaintenanceReportTemplate, 
  addCustomerTicket ,
  getTicketSiteData,
  getAvailableIssueTags,
  isTicketByCustomerAvailable
} from './ticketThunks'

const initialState = {
  tickets: null,
  current: null,
  active_filter_tickets: 'All Tickets',
  active_filter_dashboard: 'open',
  mapped: null,
  sorted: null,
  sorting: {
    isSorted: false,
    field: null,
    order: null
  },
  filtered: null,
  owned: null,
  assigned: null,
  unassigned: null,
  error: null,
  suggestions: [],
  suggestionsLoading: false,
  ticketLoading: false,
  isAscending: false,
  ticket_exists: false,
  current_ticket_exists: false,
  sendMailAlert: false,
  active_inverter_filter: 'all',
  uploadingImages: false,
  imagesUploaded: null,
  active_date_filter: null,
  active_category_filter: 'all',
  active_assigned_user: 'all',
  active_issued_user: 'all',
  techsAndFEsLoading: false,
  techsAndFEs: null,
  maintenanceReportData: null,
  maintenanceReportDownloading: false,
  maintenanceReportPreviewLoading: false,
  maintenanceReportPreviewData: null,
  maintenanceReportMail: null,
  mailingMaintenanceReport: false,
  maintenanceReportTemplatePreviewData: null,
  maintenanceReportTemplateSaving: false,
  maintenanceReportTemplateSaved: null,
  customerAddingTicket: false,
  customerAddedTicket: null,
  customerTickets: null,
  customerTicketsLoading: false,
  selectedColumns:null,
  ticketSiteData:null,
  ticketSiteDataLoading:false,
  issueTagsLoading:false,
  issueTags:null,
  ticketUpdated:Date.now(),
  issues:null,
  requestId: {
    filteredTickets:null
  },
  addingTicket:false,
  addedTicketId:null,
  ticketCounts:null,
  updatingTicket:false,
  updatedTicketId:null,
  dashboardTickets:{
    page:1,
    hasMore:true,
    filterParams:{}
  },
  isCustomerTicketAvailable:false,
  amcCategory:'all'
}

const sortFunction = (array, isAscending, compareFunction) => {
  return [...array].sort((a, b) => {
    const comparison = compareFunction(a, b);
    return isAscending ? comparison : -comparison;
  });
};

const sortByStatus = (array, isAscending) => {
  const statusOrder = { open: 1, pending: 2, closed: 3 };
  return sortFunction(array, isAscending, (a, b) => statusOrder[a.status] - statusOrder[b.status]);
};

const sortByPriority = (array, isAscending) => {
  const priorityOrder = { low: 1, normal: 2, high: 3 };
  return sortFunction(array, isAscending, (a, b) => priorityOrder[a.priority] - priorityOrder[b.priority]);
};

const sortByTitle = (array, isAscending) => {
  return sortFunction(array, isAscending, (a, b) => a.title.localeCompare(b.title));
};

const sortByDate = (array, isAscending) => {
  return sortFunction(array, isAscending, (a, b) => new Date(a.createdAt) - new Date(b.createdAt));
};

const sortByAssignedTo = (array, isAscending) => {
  return sortFunction(array, isAscending, (a, b) => {
    const nameA = a.assignedTo?.firstName?.toLowerCase() || '';
    const nameB = b.assignedTo?.firstName?.toLowerCase() || '';
    return nameA.localeCompare(nameB);
  });
};

const sortByIssuedBy = (array, isAscending) => {
  return sortFunction(array, isAscending, (a, b) => {
    const nameA = a.issuedBy?.firstName?.toLowerCase() || '';
    const nameB = b.issuedBy?.firstName?.toLowerCase() || '';
    return nameA.localeCompare(nameB);
  });
};




const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    sortTicketsDashboard(state, action) {
      const { field, userType } = action.payload;
      const targetArray = userType !== 'manager' ? state.assigned : state.owned;
      state.isAscending = !state.isAscending;

      switch (field) {
        case '_id':
          state.sorted = sortFunction(JSON.parse(JSON.stringify(targetArray)), state.isAscending, (a, b) => a._id - b._id);
          state.filtered = sortFunction(state.filtered, state.isAscending, (a, b) => a._id - b._id);
          state.unassigned = sortFunction(state.unassigned, state.isAscending, (a, b) => a._id - b._id);
          break;
        case 'status':
          state.sorted = sortByStatus(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByStatus(state.filtered, state.isAscending);
          state.unassigned = sortByStatus(state.unassigned, state.isAscending);
          break;
        case 'priorityLevel':
          state.sorted = sortByPriority(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByPriority(state.filtered, state.isAscending);
          state.unassigned = sortByPriority(state.unassigned, state.isAscending);
          break;
        case 'alertLevel':
          state.sorted = sortByPriority(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByPriority(state.filtered, state.isAscending);
          state.unassigned = sortByPriority(state.unassigned, state.isAscending);
          break;
        case 'title':
          state.sorted = sortByTitle(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByTitle(state.filtered, state.isAscending);
          state.unassigned = sortByTitle(state.unassigned, state.isAscending);
          break;
        case 'dateIssued':
          state.sorted = sortByDate(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByDate(state.filtered, state.isAscending);
          state.unassigned = sortByDate(state.unassigned, state.isAscending);
          break;
        case 'assignedTo':
          state.sorted = sortByAssignedTo(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByAssignedTo(state.filtered, state.isAscending);
          state.unassigned = sortByAssignedTo(state.unassigned, state.isAscending);
          break;
        case 'issuedBy':
          state.sorted = sortByIssuedBy(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByIssuedBy(state.filtered, state.isAscending);
          state.unassigned = sortByIssuedBy(state.unassigned, state.isAscending);
          break;
         
        default:
          return state
      }
    },
    clearSuggestions(state) {
      state.suggestions = []
    },
    clearEmailSendAlert(state) {
      state.sendMailAlert = false
    },
    clearTicket(state) {
      state.tickets = null
      state.mapped = null
      state.sorted = null
      state.filtered = null
      state.error = null
      state.current = null
    },
    setCurrent(state, action) {
      state.current = {
        ticket: action.payload.ticket,
        current_url: action.payload.current_url
      }
    },
    setTicketExists(state, action) {
      state.ticket_exists = action.payload
    },
    setCurrentTicketExists(state) {
      state.current_ticket_exists = true
    },
    clearCurrentTicketExists(state) {
      state.current_ticket_exists = false
    },
    setOwnedTickets(state, action) {
      state.owned = state.tickets.filter(
        ticket => ticket.issuedBy._id === action.payload
      )
    },
    setAssignedTickets(state, action) {
      state.assigned = state.tickets.filter(
        ticket => ticket.assignedTo._id === action.payload
      )
    },
    setAssignedFETickets(state, action) {
      state.assigned = state.tickets.filter(
        ticket => ticket.assignedToFE._id === action.payload
      )
    },
    setUnassignedTickets(state, action) {
      switch (action.payload.userType) {
        case 'manager':
            state.unassigned = state.tickets.filter(
              ticket =>
                ticket.issuedBy._id === action.payload.id &&
                ticket.assignedTo.to === 'Unassigned'
            )
            break;
        case 'technician':
            state.unassigned = state.tickets.filter(ticket => ticket.assignedTo.to === 'Unassigned')
            break;
        case 'fieldEngineer':
            state.unassigned = state.tickets.filter(ticket => ticket.assignedToFE.to === 'Unassigned')
            break;
        default:
          return  state
      }
    },
    clearCurrent(state) {
      state.current = null
    },
    filterTickets(state, action) {
      state.filtered = state.tickets.filter(ticket => {
        const regex = new RegExp(`${action.payload.text}`, 'gi')
        const isSpecialStatus =
          state.active_filter_tickets === 'All Tickets' || state.active_filter_tickets === 'My Tickets' || state.active_filter_tickets === 'Unassigned'
        const validInverter = action.payload.inverter !== 'All'
        return (
          (ticket._id.toString().match(regex) ||
            ticket.title.match(regex) ||
            ticket.description.match(regex) ||
            ticket.priority.match(regex) ||
            ticket.status.match(regex) ||
            ticket.issuedBy.firstName.match(regex) ||
            ticket.issuedBy.lastName.match(regex))
          &&
          (!isSpecialStatus ? ticket.status === state.active_filter_tickets.toLowerCase() : state.active_filter_tickets === 'Unassigned' ? (ticket.assignedTo.to === 'Unassigned' && ticket.assignedToFE.to === 'Unassigned') : state.active_filter_tickets === 'My Tickets' ? ticket.issuedBy._id === action.payload.user._id : true)
          && (validInverter ? ticket.site.inverter_name === action.payload.inverter : true)
        )
      })
      state.mapped = state.tickets.filter(ticket => {
        const regex = new RegExp(`${action.payload.text}`, 'gi')
        const isSpecialStatus =
          state.active_filter_tickets === 'All Tickets' || state.active_filter_tickets === 'My Tickets' || state.active_filter_tickets === 'Unassigned'
        const validInverter = action.payload.inverter !== 'All'
        return (
          (ticket._id.toString().match(regex) ||
            ticket.title.match(regex) ||
            ticket.description.match(regex) ||
            ticket.priority.match(regex) ||
            ticket.status.match(regex) ||
            ticket.issuedBy.firstName.match(regex) ||
            ticket.issuedBy.lastName.match(regex))
          &&
          (!isSpecialStatus ? ticket.status === state.active_filter_tickets.toLowerCase() : state.active_filter_tickets === 'Unassigned' ? ticket.assignedTo.to === 'Unassigned' : state.active_filter_tickets === 'My Tickets' ? ticket.issuedBy._id === action.payload.user._id : true)
          && (validInverter ? ticket.site.inverter_name === action.payload.inverter : true)
        )
      })
    },
    setFilter(state, action) {
      if (action.payload.current_url !== 'dashboard') {
          state.active_filter_tickets = action.payload.filter
          state.mapped = action.payload.tickets
          state.sorted = action.payload.tickets
          state.filtered = action.payload.tickets 
      } else {
        if (action.payload.userType === 'manager') {
            // state.active_filter_dashboard = action.payload.filter
            state.owned = action.payload.tickets
            state.filtered = action.payload.tickets
        } else {
            // state.active_filter_dashboard = action.payload.filter
            state.assigned = action.payload.tickets
            state.filtered = action.payload.tickets
        }
      }
    },
    setSort(state, action) {
      state.sorting = {
        isSorted: action.payload.isSorted,
        field: action.payload.field,
        order: action.payload.order
      }
    },
    resetSort(state) {
      state.sorting = {
        isSorted: false,
        field: null,
        order: null
      }
      state.sorted = null
    },
    clearFilter(state) {
      state.filtered = null
    },
    resetTicketState() {
      localStorage.removeItem('currentTicket')
      return initialState
    },
    setInverterFilter(state, action) {
      state.active_inverter_filter = action.payload
    },
    setMaintenanceReportPreviewData(state, action) {
      state.maintenanceReportTemplatePreviewData = action.payload
    },
    sortTickets(state, action) {
      const targetArray = state.mapped
      state.isAscending = !state.isAscending;

      switch (action.payload) {
        case '_id':
          state.sorted = sortFunction(JSON.parse(JSON.stringify(targetArray)), state.isAscending, (a, b) => a._id - b._id);
          state.filtered = sortFunction(state.filtered, state.isAscending, (a, b) => a._id - b._id);
          break;
        case 'status':
          state.sorted = sortByStatus(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByStatus(state.filtered, state.isAscending);
          break;
        case 'priorityLevel':
          state.sorted = sortByPriority(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByPriority(state.filtered, state.isAscending);
          break;
        case 'alertLevel':
          state.sorted = sortByPriority(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByPriority(state.filtered, state.isAscending);
          break;
        case 'title':
          state.sorted = sortByTitle(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByTitle(state.filtered, state.isAscending);
          break;
        case 'dateIssued':
          state.sorted = sortByDate(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByDate(state.filtered, state.isAscending);
          break;
        case 'assignedTo':
          state.sorted = sortByAssignedTo(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByAssignedTo(state.filtered, state.isAscending);
          break;
        case 'issuedBy':
          state.sorted = sortByIssuedBy(JSON.parse(JSON.stringify(targetArray)), state.isAscending);
          state.filtered = sortByIssuedBy(state.filtered, state.isAscending);
          break;
         
        default:
          return state
      }
       
    },
    setDateFilter(state, action) {
      state.active_date_filter = action.payload
    },
    setCategoryFilter(state, action) {
      state.active_category_filter = action.payload
    },
    setAssignedUserFilter(state, action) {
      state.active_assigned_user = action.payload
    },
    setStatusFilter(state, action) {
      state.active_filter_dashboard = action.payload
    },
    setColumns(state,action){
      state.selectedColumns = action.payload
    },
    setSelectedIssues(state,action){
      state.issues = action.payload
    },
    clearTickets(state){
      state.filtered = null
      // state.tickets = null
    },
    incrementDashboardPage(state){
      state.dashboardTickets.page +=1
    },
    setDashboardFilters(state,action){
      state.dashboardTickets.filterParams = action.payload
      state.dashboardTickets.page = 1
      state.filtered = null
      state.dashboardTickets.hasMore = true
    },
    setIssuedUserFilter(state, action) {
      state.active_issued_user = action.payload
    },
    setAmcCategory(state, action) {
      state.amcCategory = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTicket.pending, (state) => {
        state.ticketLoading = true;
        state.error = null
        state.current = null
      })
      .addCase(getTicket.fulfilled, (state, action) => {
        state.current = {
          ticket: action.payload.ticket,
          current_url: action.payload.current_url
        }
        state.ticketLoading = false
      })
      .addCase(getTicket.rejected, (state, action) => {
        state.error = action.payload 
        state.ticketLoading = false 
      })
      .addCase(getSiteNames.pending, (state) => {
        state.suggestionsLoading = true;
        state.error = null
      })
      .addCase(getSiteNames.fulfilled, (state, action) => {
        state.suggestions = action.payload.suggestions
        state.suggestionsLoading = false
      })
      .addCase(getSiteNames.rejected, (state, action) => {
        state.error = action.payload
        state.suggestionsLoading = false
      })
      .addCase(getTickets.pending, (state) => {
        state.ticketLoading = true;
        state.error = null
      })
      .addCase(getTickets.fulfilled, (state, action) => {
        state.tickets = action.payload
        state.mapped = action.payload?.map(ticket => ticket)
        state.ticketLoading = false
      })
      .addCase(getTickets.rejected, (state, action) => {
        state.error = action.payload
        state.ticketLoading = false
      })
      .addCase(addTicket.pending, (state) => {
        state.addingTicket = true
        state.addedTicketId = null
      })
      .addCase(addTicket.fulfilled, (state, action) => {
        if (state.sorted !== null && state.filtered !== null) {
          if (action.payload.userType === 'manager') {

            return Object.assign({}, state, {
              tickets: [action.payload.ticket].concat(state.filtered ? state.filtered.filter(ticket => ticket !== null) : []),
              owned: [action.payload.ticket].concat(state.owned ? state.owned.filter(ticket => ticket !== null) : []),
              mapped: [action.payload.ticket].concat(state.filtered ? state.filtered.filter(ticket => ticket !== null) : []),
              sorted: [action.payload.ticket].concat(state.filtered ? state.filtered.filter(ticket => ticket !== null) : []),
              filtered: [action.payload.ticket].concat(state.filtered ? state.filtered.filter(ticket => ticket !== null) : []),
              addingTicket: false,
              addedTicketId: action.payload.ticket._id
            });
          }
          else {
            return Object.assign({}, state, {
              tickets: [action.payload.ticket].concat(state.tickets ? state.tickets.filter(ticket => ticket !== null) : []),
              assigned: [action.payload.ticket].concat(state.assigned ? state.assigned.filter(ticket => ticket !== null) : []),
              mapped: [action.payload.ticket].concat(state.tickets ? state.tickets.filter(ticket => ticket !== null) : []),
              sorted: [action.payload.ticket].concat(state.filtered ? state.tickets.filter(ticket => ticket !== null) : []),
              filtered: [action.payload.ticket].concat(state.filtered ? state.filtered.filter(ticket => ticket !== null) : []),
              addingTicket: false,
              addedTicketId: action.payload.ticket._id
            });
          }
        }
        else {
          if (state.filtered !== null) {
            if (action.payload.userType === 'manager') {
              return Object.assign({}, state, {
                tickets: [action.payload.ticket].concat(state.filtered ? state.filtered.filter(ticket => ticket !== null) : []),
                mapped: [action.payload.ticket].concat(state.filtered ? state.filtered.filter(ticket => ticket !== null) : []),
                owned: [action.payload.ticket].concat(state.owned ? state.owned.filter(ticket => ticket !== null) : []),
                filtered: [action.payload.ticket].concat(state.filtered ? state.filtered.filter(ticket => ticket !== null) : []),
              addingTicket: false,
              addedTicketId: action.payload.ticket._id
              });
            }
            else {
              return Object.assign({}, state, {
                tickets: [action.payload.ticket].concat(state.tickets ? state.tickets.filter(ticket => ticket !== null) : []),
                mapped: [action.payload.ticket].concat(state.tickets ? state.tickets.filter(ticket => ticket !== null) : []),
                assigned: [action.payload.ticket].concat(state.assigned ? state.assigned.filter(ticket => ticket !== null) : []),
                filtered: [action.payload.ticket].concat(state.filtered ? state.filtered.filter(ticket => ticket !== null) : []),
              addingTicket: false,
              addedTicketId: action.payload.ticket._id
              });
            }
          }
          else {
            if (action.payload.userType === 'manager') {
              return Object.assign({}, state, {
                tickets: [action.payload.ticket].concat( state.filtered ? state.filtered.filter(ticket => ticket !== null) : []),
                mapped: [action.payload.ticket].concat( state.filtered ? state.filtered.filter(ticket => ticket !== null) : []),
                owned: [action.payload.ticket].concat(state.owned ? state.owned.filter(ticket => ticket !== null) : []),
              addingTicket: false,
              addedTicketId: action.payload.ticket._id
              });
            } else {
              return Object.assign({}, state, {
                tickets: [action.payload.ticket].concat(state.tickets ? state.tickets.filter(ticket => ticket !== null) : []),
                mapped: [action.payload.ticket].concat(state.tickets ? state.tickets.filter(ticket => ticket !== null) : []),
                assigned: [action.payload.ticket].concat(state.assigned ? state.assigned.filter(ticket => ticket !== null) : []),
              addingTicket: false,
              addedTicketId: action.payload.ticket._id
              });
            }
          }
        }
      })
      .addCase(addTicket.rejected, (state, action) => {
        state.error = action.payload
        state.addingTicket = false
        state.addedTicketId = null
      })
      .addCase(deleteTicket.fulfilled, (state, action) => {
        if (action.payload.current_url === 'tickets') {
          if (state.sorted !== null && state.filtered !== null) {
            return Object.assign({}, state, {
              tickets: state.tickets.filter(ticket => ticket._id !== action.payload.id),
              mapped: state.mapped.filter(ticket => ticket._id !== action.payload.id),
              sorted: state.sorted.filter(ticket => ticket._id !== action.payload.id),
              filtered: state.filtered.filter(ticket => ticket._id !== action.payload.id)
            });
          } else {
            return Object.assign({}, state, {
              tickets: state.tickets.filter(ticket => ticket._id !== action.payload.id),
              mapped: state.mapped.filter(ticket => ticket._id !== action.payload.id),
              filtered: state.filtered.filter(ticket => ticket._id !== action.payload.id)
            });
          }
        } else {
          if (action.payload.userType !== 'manager') {
            if (state.sorted !== null && state.filtered !== null) {
              return Object.assign({}, state, {
                tickets: state.tickets.filter(ticket => ticket._id !== action.payload.id),
                assigned: state.assigned.filter(ticket => ticket._id !== action.payload.id),
                sorted: state.sorted.filter(ticket => ticket._id !== action.payload.id),
                filtered: state.filtered.filter(ticket => ticket._id !== action.payload.id)
              });
            } else {
              return Object.assign({}, state, {
                tickets: state.tickets.filter(ticket => ticket._id !== action.payload.id),
                assigned: state.assigned.filter(ticket => ticket._id !== action.payload.id),
                filtered: state.filtered.filter(ticket => ticket._id !== action.payload.id)
              });
            }
          } else {
            if (state.sorted !== null && state.filtered !== null) {
              return Object.assign({}, state, {
                tickets: state.tickets.filter(ticket => ticket._id !== action.payload.id),
                owned: state.owned.filter(ticket => ticket._id !== action.payload.id),
                sorted: state.sorted.filter(ticket => ticket._id !== action.payload.id),
                filtered: state.filtered.filter(ticket => ticket._id !== action.payload.id)
              });
            } else {
              return Object.assign({}, state, {
                tickets: state.tickets.filter(ticket => ticket._id !== action.payload.id),
                owned: state.owned.filter(ticket => ticket._id !== action.payload.id),
                filtered: state.filtered.filter(ticket => ticket._id !== action.payload.id)
              });
            }
          }
        }
      })
      .addCase(deleteTicket.rejected, (state, action) => {
        state.error = action.payload
      })
      .addCase(updateTicket.pending, (state) => {
        state.updatingTicket = true
        state.updatedTicketId = null
      })
      .addCase(updateTicket.fulfilled, (state, action) => {
        if (action.payload.current_url === 'tickets') {
          if (state.sorted !== null && state.filtered !== null) {
            return Object.assign({}, state, {
              tickets: state.tickets?.map(ticket => ticket._id === action.payload.ticket._id ? action.payload.ticket : ticket),
              mapped: state.mapped?.map(ticket => ticket._id === action.payload.ticket._id ? action.payload.ticket : ticket),
              sorted: state.sorted?.map(ticket => ticket._id === action.payload.ticket._id ? action.payload.ticket : ticket),
              filtered: state.filtered?.map(ticket => ticket._id === action.payload.ticket._id ? action.payload.ticket : ticket),
              unassigned: state.unassigned?.map(ticket => ticket._id === action.payload.ticket._id ? action.payload.ticket : ticket),
              current: {
                ticket: action.payload.ticket,
                current_url: action.payload.current_url
              },
              updatingTicket: false,
              updatedTicketId: action.payload.ticket._id
            });
          }
          else {
            if (state.filtered !== null) {
              return Object.assign({}, state, {
                tickets: state.tickets?.map(ticket => ticket._id === action.payload.ticket._id ? action.payload.ticket : ticket),
                mapped: state.mapped?.map(ticket => ticket._id === action.payload.ticket._id ? action.payload.ticket : ticket),
                filtered: state.filtered?.map(ticket => ticket._id === action.payload.ticket._id ? action.payload.ticket : ticket),
                current: {
                  ticket: action.payload.ticket,
                  current_url: action.payload.current_url
                },
                updatingTicket: false,
                updatedTicketId: action.payload.ticket._id
              });
            }
            else {
              return Object.assign({}, state, {
                tickets: state.tickets?.map(ticket => ticket._id === action.payload.ticket._id ? action.payload.ticket : ticket),
                mapped: state.mapped?.map(ticket => ticket._id === action.payload.ticket._id ? action.payload.ticket : ticket),
                current: {
                  ticket: action.payload.ticket,
                  current_url: action.payload.current_url
                },
                updatingTicket: false,
                updatedTicketId: action.payload.ticket._id
              });
            }
          }
        }
        else if (action.payload.current_url === 'ticket') {
          if (action.payload.userType !== 'manager') {
            if (state.sorted !== null && state.filtered !== null) {
              return Object.assign({}, state, {
                tickets: state.tickets?.map(ticket => ticket._id === action.payload.ticket._id ? action.payload.ticket : ticket),
                assigned: state.tickets?.map(ticket => ticket._id === action.payload.ticket._id ? action.payload.ticket : ticket).filter(ticket => ticket.assignedTo._id === action.payload.userID),
                sorted: state.sorted?.map(ticket => ticket._id === action.payload.ticket._id ? action.payload.ticket : ticket),
                filtered: state.filtered
                  ?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  )
                  .filter(
                    ticket => ticket.assignedTo._id === action.payload.userID
                  ),
                unassigned: state.tickets
                  ?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  )
                  .filter(ticket => ticket.assignedTo.to === 'Unassigned'),
                current: {
                  ticket: action.payload.ticket,
                  current_url: action.payload.current_url
                },
                updatingTicket: false,
                updatedTicketId: action.payload.ticket._id
              });
            } else {
              if (state.filtered !== null) {
                return Object.assign({}, state, {
                  tickets: state.tickets?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  assigned: state.tickets
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(
                      ticket => ticket.assignedTo._id === action.payload.userID
                    ),
                  filtered: state.filtered
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(
                      ticket => ticket.assignedTo._id === action.payload.userID
                    ),
                  unassigned: state.tickets
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(ticket => ticket.assignedTo.to === 'Unassigned'),
                  current: {
                    ticket: action.payload.ticket,
                    current_url: action.payload.current_url
                  },
                  updatingTicket: false,
                  updatedTicketId: action.payload.ticket._id
                });
              } else {
                return Object.assign({}, state, {
                  tickets: state.tickets?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  assigned: state.tickets
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(
                      ticket => ticket.assignedTo._id === action.payload.userID
                    ),
                  unassigned: state.tickets
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(ticket => ticket.assignedTo.to === 'Unassigned'),
                  current: {
                    ticket: action.payload.ticket,
                    current_url: action.payload.current_url
                  },
                  updatingTicket: false,
                  updatedTicketId: action.payload.ticket._id
                });
              }
            }
          } else {
            if (state.sorted !== null && state.filtered !== null) {
              return Object.assign({}, state, {
                tickets: state.tickets?.map(ticket =>
                  ticket._id === action.payload.ticket._id
                    ? action.payload.ticket
                    : ticket
                ),
                owned: state.owned?.map(ticket =>
                  ticket._id === action.payload.ticket._id
                    ? action.payload.ticket
                    : ticket
                ),
                sorted: state.sorted?.map(ticket =>
                  ticket._id === action.payload.ticket._id
                    ? action.payload.ticket
                    : ticket
                ),
                filtered: state.filtered?.map(ticket =>
                  ticket._id === action.payload.ticket._id
                    ? action.payload.ticket
                    : ticket
                ),
                unassigned: state.tickets
                  ?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  )
                  .filter(
                    ticket =>
                      ticket.assignedTo.to === 'Unassigned' &&
                      ticket.issuedBy._id === action.payload.userID
                  ),
                current: {
                  ticket: action.payload.ticket,
                  current_url: action.payload.current_url
                },
                updatingTicket: false,
                updatedTicketId: action.payload.ticket._id
              });
            } else {
              if (state.filtered !== null) {
                return Object.assign({}, state, {
                  tickets: state.tickets?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  owned: state.owned?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  filtered: state.filtered?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  unassigned: state.tickets
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(
                      ticket =>
                        ticket.assignedTo.to === 'Unassigned' &&
                        ticket.issuedBy._id === action.payload.userID
                    ),
                  current: {
                    ticket: action.payload.ticket,
                    current_url: action.payload.current_url
                  },
                  updatingTicket: false,
                  updatedTicketId: action.payload.ticket._id
                });
              } else {
                return Object.assign({}, state, {
                  tickets: state.tickets?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  owned: state.owned?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  unassigned: state.tickets
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(
                      ticket =>
                        ticket.assignedTo.to === 'Unassigned' &&
                        ticket.issuedBy._id === action.payload.userID
                    ),
                  current: {
                    ticket: action.payload.ticket,
                    current_url: action.payload.current_url
                  },
                  updatingTicket: false,
                  updatedTicketId: action.payload.ticket._id
                });
              }
            }
          }
        } else {
          if (action.payload.userType !== 'manager') {
            if (state.sorted !== null && state.filtered !== null) {
              return Object.assign({}, state, {
                tickets: state.tickets?.map(ticket =>
                  ticket._id === action.payload.ticket._id
                    ? action.payload.ticket
                    : ticket
                ),
                assigned: state.tickets
                  ?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  )
                  .filter(
                    ticket => ticket.assignedTo._id === action.payload.userID
                  ),
                sorted: state.sorted?.map(ticket =>
                  ticket._id === action.payload.ticket._id
                    ? action.payload.ticket
                    : ticket
                ),
                filtered: state.filtered
                  ?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  )
                  .filter(
                    ticket => ticket.assignedTo._id === action.payload.userID
                  ),
                unassigned: state.tickets
                  ?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  )
                  .filter(ticket => ticket.assignedTo.to === 'Unassigned'),
                current: {
                  ticket: action.payload.ticket,
                  current_url: action.payload.current_url
                },
                updatingTicket: false,
                updatedTicketId: action.payload.ticket._id
              });
            } else {
              if (state.filtered !== null) {
                return Object.assign({}, state, {
                  tickets: state.tickets?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  assigned: state.tickets
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(
                      ticket => ticket.assignedTo._id === action.payload.userID
                    ),
                  filtered: state.filtered
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(
                      ticket => ticket.assignedTo._id === action.payload.userID
                    ),
                  unassigned: state.tickets
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(ticket => ticket.assignedTo.to === 'Unassigned'),
                  current: {
                    ticket: action.payload.ticket,
                    current_url: action.payload.current_url
                  },
                  updatingTicket: false,
                  updatedTicketId: action.payload.ticket._id
                });
              } else {
                return Object.assign({}, state, {
                  tickets: state.tickets?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  assigned: state.tickets
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(
                      ticket => ticket.assignedTo._id === action.payload.userID
                    ),
                  unassigned: state.tickets
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(ticket => ticket.assignedTo.to === 'Unassigned'),
                  current: {
                    ticket: action.payload.ticket,
                    current_url: action.payload.current_url
                  },
                  updatingTicket: false,
                  updatedTicketId: action.payload.ticket._id
                });
              }
            }
          } else {
            if (state.sorted !== null && state.filtered !== null) {
              return Object.assign({}, state, {
                tickets: state.tickets?.map(ticket =>
                  ticket._id === action.payload.ticket._id
                    ? action.payload.ticket
                    : ticket
                ),
                owned: state.owned?.map(ticket =>
                  ticket._id === action.payload.ticket._id
                    ? action.payload.ticket
                    : ticket
                ),
                sorted: state.sorted?.map(ticket =>
                  ticket._id === action.payload.ticket._id
                    ? action.payload.ticket
                    : ticket
                ),
                filtered: state.filtered?.map(ticket =>
                  ticket._id === action.payload.ticket._id
                    ? action.payload.ticket
                    : ticket
                ),
                unassigned: state.tickets
                  ?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  )
                  .filter(
                    ticket =>
                      ticket.assignedTo.to === 'Unassigned' &&
                      ticket.issuedBy._id === action.payload.userID
                  ),
                current: {
                    ticket: action.payload.ticket,
                    current_url: action.payload.current_url
                  },
                  updatingTicket: false,
                  updatedTicketId: action.payload.ticket._id
              });
            } else {
              if (state.filtered !== null) {
                return Object.assign({}, state, {
                  tickets: state.tickets?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  owned: state.owned?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  filtered: state.filtered?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  unassigned: state.tickets
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(
                      ticket =>
                        ticket.assignedTo.to === 'Unassigned' &&
                        ticket.issuedBy._id === action.payload.userID
                    ),
                    current: {
                      ticket: action.payload.ticket,
                      current_url: action.payload.current_url
                    },
                    updatingTicket: false,
                    updatedTicketId: action.payload.ticket._id
                });
              } else {
                return Object.assign({}, state, {
                  tickets: state.tickets?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  owned: state.owned?.map(ticket =>
                    ticket._id === action.payload.ticket._id
                      ? action.payload.ticket
                      : ticket
                  ),
                  unassigned: state.tickets
                    ?.map(ticket =>
                      ticket._id === action.payload.ticket._id
                        ? action.payload.ticket
                        : ticket
                    )
                    .filter(
                      ticket =>
                        ticket.assignedTo.to === 'Unassigned' &&
                        ticket.issuedBy._id === action.payload.userID
                    ),
                    current: {
                      ticket: action.payload.ticket,
                      current_url: action.payload.current_url
                    },
                    updatingTicket: false,
                    updatedTicketId: action.payload.ticket._id
                });
              }
            }
          }
        }

      })
      .addCase(updateTicket.rejected, (state, action) => {
        state.error = action.payload
      })
      .addCase(updateNameOnTickets.fulfilled, (state, action) => {
          state.tickets = state.tickets?.map(ticket => {
            return action.payload.forEach(updated_ticket => {
              if (ticket._id === updated_ticket._id) {
                return updated_ticket
              }
            })
          })
         
      })
      .addCase(updateNameOnTickets.rejected, (state, action) => {
        state.error = action.payload
      })
      .addCase(sendMail.fulfilled, (state, action) => {
        state.sendMailAlert = action.payload
      })
      .addCase(sendMail.rejected, (state, action) => {
        state.error = action.payload
      })
      .addCase(uploadMaintenanceImages.pending, (state) => {
        state.uploadingImages = true;
        state.error = null
      })
      .addCase(uploadMaintenanceImages.fulfilled, (state, action) => {
        state.imagesUploaded = action.payload.status
        state.uploadingImages = false
        state.current = {
          ticket: action.payload.ticket
        }
      })
      .addCase(uploadMaintenanceImages.rejected, (state, action) => {
        state.error = action.payload
        state.uploadingImages = false
      })
      .addCase(getTechsAndFEs.pending, (state) => {
        state.techsAndFEsLoading = true;
        state.error = null
      })
      .addCase(getTechsAndFEs.fulfilled, (state, action) => {
        state.techsAndFEs = action.payload
        state.techsAndFEsLoading = false
      })
      .addCase(getTechsAndFEs.rejected, (state, action) => {
        state.error = action.payload
        state.techsAndFEsLoading = false
      })
      .addCase(getFilteredTickets.pending, (state, action) =>{
        state.ticketLoading = true
        state.error = null
        state.requestId.filteredTickets = action.meta.arg.requestId;
        // state.ticketCounts = null
      })
      .addCase(getFilteredTickets.fulfilled, (state, action) => {
        if (state.requestId.filteredTickets === action.payload?.requestId) {
        if (action.payload.current_url !== 'dashboard') {
          state.active_filter_tickets = action.payload.filter
          state.mapped = [...(state.filtered ?? []),...action.payload.tickets]
          state.sorted = [...(state.filtered ?? []),...action.payload.tickets]
          state.filtered = [...(state.filtered ?? []),...action.payload.tickets]
        } else {
          if (action.payload.userType === 'manager') {
            // state.active_filter_dashboard = action.payload.filter
            state.owned = [...(state.filtered ?? []),...action.payload.tickets]
            state.filtered = [...(state.filtered ?? []),...action.payload.tickets]
          } else {
            // state.active_filter_dashboard = action.payload.filter
            state.assigned = [...(state.filtered ?? []),...action.payload.tickets]
            state.filtered = [...(state.filtered ?? []),...action.payload.tickets]
          }
        }
        if(action.payload.tickets && (action.payload.tickets.length === 0 || action.payload.tickets.length < 10 )){
          state.dashboardTickets.hasMore = false
        }
        state.dashboardTickets.page = action.payload.page
        state.ticketLoading = false
        state.ticketCounts = action.payload.counts
      }
      })
      .addCase(getFilteredTickets.rejected, (state, action) => {
        state.error = action.payload
        state.ticketLoading = false
      })
      .addCase(downloadMaintenanceReport.pending, (state) => {
        state.maintenanceReportData = null
        state.maintenanceReportDownloading = true
        state.error = null
      })
      .addCase(downloadMaintenanceReport.fulfilled, (state, action) => {
        state.maintenanceReportData = action.payload
        state.maintenanceReportDownloading = false
      })
      .addCase(downloadMaintenanceReport.rejected, (state, action) => {
        state.error = action.payload
        state.maintenanceReportDownloading = false
      })
      .addCase(previewMaintenanceReport.pending, (state) => {
        state.maintenanceReportPreviewData = null
        state.maintenanceReportPreviewLoading = true
        state.error = null
      })
      .addCase(previewMaintenanceReport.fulfilled, (state, action) => {
        state.maintenanceReportPreviewData = action.payload
        state.maintenanceReportPreviewLoading = false
      })
      .addCase(previewMaintenanceReport.rejected, (state, action) => {
        state.error = action.payload
        state.maintenanceReportPreviewLoading = false
      })
      .addCase(mailMaintenanceReport.pending, (state) => {
        state.maintenanceReportMail = null
        state.mailingMaintenanceReport = true
        state.error = null
      })
      .addCase(mailMaintenanceReport.fulfilled, (state, action) => {
        state.maintenanceReportMail = action.payload
        state.mailingMaintenanceReport = false
      })
      .addCase(mailMaintenanceReport.rejected, (state, action) => {
        state.error = action.payload
        state.mailingMaintenanceReport = false
      })
      .addCase(saveMaintenanceReportTemplate.pending, (state) => {
        state.maintenanceReportTemplateSaved = null
        state.maintenanceReportTemplateSaving = true
        state.error = null
      })
      .addCase(saveMaintenanceReportTemplate.fulfilled, (state, action) => {
        state.maintenanceReportTemplateSaved = action.payload
        state.maintenanceReportTemplateSaving = false
      })
      .addCase(saveMaintenanceReportTemplate.rejected, (state, action) => {
        state.error = action.payload
        state.maintenanceReportTemplateSaving = false
      })
      .addCase(addCustomerTicket.pending, (state) => {
        state.customerAddedTicket = null
        state.customerAddingTicket = true
        state.error = null
      })
      .addCase(addCustomerTicket.fulfilled, (state, action) => {
        state.customerAddedTicket = action.payload
        state.customerAddingTicket = false
        state.tickets = [action.payload,...state.tickets]
      })
      .addCase(addCustomerTicket.rejected, (state, action) => {
        state.error = action.payload
        state.customerAddingTicket = false
      })
      .addCase(getTicketSiteData.pending, (state) => {
        state.ticketSiteDataLoading = true;
        state.error = null
      })
      .addCase(getTicketSiteData.fulfilled, (state, action) => {
        state.ticketSiteData = action.payload
        state.ticketSiteDataLoading = false
      })
      .addCase(getTicketSiteData.rejected, (state, action) => {
        state.error = action.payload 
        state.ticketSiteDataLoading = false 
      })
      .addCase(getAvailableIssueTags.pending, (state) => {
        state.issueTagsLoading = true;
        state.error = null
      })
      .addCase(getAvailableIssueTags.fulfilled, (state, action) => {
        state.issueTags = action.payload
        state.issueTagsLoading = false
      })
      .addCase(getAvailableIssueTags.rejected, (state, action) => {
        state.error = action.payload 
        state.issueTagsLoading = false 
      })
      .addCase(isTicketByCustomerAvailable.pending, (state) => {
        state.isCustomerTicketAvailable = false;
        state.error = null
      })
      .addCase(isTicketByCustomerAvailable.fulfilled, (state, action) => {
        state.isCustomerTicketAvailable = action.payload.customerTicketAvailable || false
      })
      .addCase(isTicketByCustomerAvailable.rejected, (state, action) => {
        state.error = action.payload 
        state.isCustomerTicketAvailable = false 
      })
  }
})

export const { 
  clearSuggestions,
  sortTickets,
  sortTicketsDashboard,
  clearEmailSendAlert, 
  clearTicket, 
  setCurrent, 
  setTicketExists, 
  setCurrentTicketExists, 
  clearCurrentTicketExists, 
  setOwnedTickets, 
  setAssignedTickets, 
  setAssignedFETickets, 
  setUnassignedTickets, 
  clearCurrent, 
  filterTickets, 
  setFilter, 
  setSort, 
  resetSort, 
  clearFilter, 
  resetTicketState, 
  setInverterFilter, 
  setMaintenanceReportPreviewData, 
  setDateFilter, 
  setCategoryFilter, 
  setAssignedUserFilter, 
  setStatusFilter,
  setColumns,
  setSelectedIssues,
  clearTickets,
  incrementDashboardPage,
  setDashboardFilters,
  setIssuedUserFilter,
  setAmcCategory 
} = ticketSlice.actions
export default ticketSlice.reducer