import { createSlice } from '@reduxjs/toolkit'
import { 
  addIntegration,
  getIntegrations
 } from './integrationsThunks'


const initialState = {
  integrationsData: null,
  error: null,
  integrationsLoading: false,
  integrationCreatedAt:''
}

const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {

    resetIntegrationState() {
      return initialState
    },
    clearIntegrationState(state,action){
      const keysToClear = action.payload;
      keysToClear.forEach(key => {
        if (initialState.hasOwnProperty(key)) {
          state[key] = initialState[key];
        }
      });
    }
  },
  extraReducers(builder) {
    builder
    .addCase(addIntegration.pending, (state) => {
      state.integrationsLoading = true;
      state.integrationCreatedAt = ''
      state.error = null
    })
    .addCase(addIntegration.fulfilled, (state, action) => {
      state.integrationCreatedAt = action.payload.createdAt
      state.integrationsLoading = false
    })
    .addCase(addIntegration.rejected, (state, action) => {
      state.error = action.payload
      state.integrationsLoading = false
    })
    .addCase(getIntegrations.pending, (state) => {
      state.integrationsLoading = true;
      state.integrationsData = null
      state.error = null
    })
    .addCase(getIntegrations.fulfilled, (state, action) => {
      state.integrationsData = action.payload.result
      state.integrationsLoading = false
    })
    .addCase(getIntegrations.rejected, (state, action) => {
      state.error = action.payload
      state.integrationsLoading = false
    })
  }
})
export const { resetIntegrationState, clearIntegrationState } = integrationSlice.actions

export default integrationSlice.reducer;