import { createAsyncThunk } from '@reduxjs/toolkit';
import {axiosInstance} from '../../utils/axiosInstance';
import { downloadPDF } from '../../utils/AnalyticsHelper';
import axios from 'axios';
import { getAbortController } from '../../utils/abortControllerUtils';

export const getTicket = createAsyncThunk('ticket/getTicket', async ({ id = '', current_url = '' } = {}, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/ticket/${id}`)
    return { ticket: res.data, current_url }
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getSiteNames = createAsyncThunk('ticket/getSiteNames', async ( userInput = '' , { rejectWithValue }) => {
  // Set Loading to True
  try {
    const res = await axiosInstance.get(`/v1/tenant/sites?input=${userInput}`)
    return { suggestions: res.data }
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getTickets = createAsyncThunk('ticket/getTickets', async ({ sortBy = '', sortOrder = '' } = {}, { rejectWithValue }) => {
  // Set Loading to True
  try {
    const sortQueryString = sortBy && sortOrder ? `?sortBy=${sortBy}&sortOrder=${sortOrder}` : ''
    const res = await axiosInstance.get(`/v1/tenant/tickets${sortQueryString}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const addTicket = createAsyncThunk('ticket/addTicket', async ({ formData = {}, userType = '' } = {}, { rejectWithValue }) => {
  try {
    
    const res = await axiosInstance.post('/v1/tenant/ticket', formData)
    const ticket = res.data
    return { ticket, userType }
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const deleteTicket = createAsyncThunk('ticket/deleteTicket', async ({ id = '', userType = '', current_url = '' } = {}, { rejectWithValue }) => {
  try {
    await axiosInstance.delete(`/v1/tenant/ticket/${id}`)
    return { id, userType, current_url }
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateTicket = createAsyncThunk('ticket/updateTicket', async ({ formData = {}, userID = '', userType = '', current_url = '', ticketId = '' } = {}, { rejectWithValue, dispatch }) => {
  try {

    const res = await axiosInstance.put(`/v1/tenant/ticket/${ticketId}`, formData)

    //after changing to update page instead of modal, when going back to ticket page after update, date not getting correctly updated. so dispatching to get the ticket
    // dispatch(getTicket({ id: ticketId }));
    return { ticket: res.data, userID, userType, current_url }
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateNameOnTickets = createAsyncThunk('ticket/updateNameOnTickets', async ( user = '' , { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    // Update User's name in tickets database
    const res = await axiosInstance.put(`/v1/tenant/user/tickets/${user.id}`, user, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const sendMail = createAsyncThunk('ticket/sendMail', async ({ ticket  = '', userSelections  = '' } = {}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-host-name': window.location.hostname
      }
    }

    const res = await axiosInstance.post(`/v1/tenant/ticket/sendEmail`, { ticket, userSelections }, config)
    return res.data.status
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const uploadMaintenanceImages = createAsyncThunk('ticket/uploadMaintenanceImages', async ({ ticket = '', data = '' } = {}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const res = await axiosInstance.post(`/v1/tenant/ticket/upload-images/${ticket}`, data, config)
    return { status: res.data.status, ticket: res.data.ticket }
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getTechsAndFEs = createAsyncThunk('ticket/getTechsAndFEs', async (_, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/users/allTechsAndFEs`)
    return res.data;
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getFilteredTickets = createAsyncThunk('ticket/getFilteredTickets', async ({ date = '', category = '', assignedTo = '', status = '', inverter = '', search = '',issue = '', requestId = '', page = 1, sortBy = '', sortOrder = '', issuedUserType = '', limit = 10, amcCategory = '' } = {}, { rejectWithValue }) => {
  try {

    const currentAbortController = getAbortController('getFilteredTickets');
    
    const categoryString = Array.isArray(category) ? category.join(',') : category;
    const issueStatusString = Array.isArray(issue) ? issue.join(',') : issue;
    const res = await axiosInstance.get(`/v1/tenant/filteredTickets`, {
        params: { date, category: categoryString, assignedTo, status, inverter, search, issue:issueStatusString, page, sortBy, sortOrder, issuedUserType, limit, amcCategory},
        signal: currentAbortController.signal,
      })
    const { filter, tickets, current_url, userType, counts } = res.data
    return { filter, tickets, current_url, userType, requestId, counts, page }
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
}
})

export const downloadMaintenanceReport = createAsyncThunk('ticket/downloadMaintenanceReport', async ( data = '' , { rejectWithValue }) => {
  try {
    const res = await axiosInstance.post('/v1/tenant/maintenanceReport', data, {
      responseType: 'blob', // This tells axiosInstance to expect a Blob response
    })
    downloadPDF(res.data)
    return res.data

  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const previewMaintenanceReport = createAsyncThunk('ticket/previewMaintenanceReport', async (data = '' , { rejectWithValue }) => {
  try {
    const res = await axiosInstance.post('/v1/tenant/maintenanceReport', data, {
      responseType: 'blob', // This tells axiosInstance to expect a Blob response
    })
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const mailMaintenanceReport = createAsyncThunk('ticket/mailMaintenanceReport', async ( data = '' , { rejectWithValue }) => {
  try {
    const res = await axiosInstance.post('/v1/tenant/mail-maintenanceReport', data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const saveMaintenanceReportTemplate = createAsyncThunk('ticket/saveMaintenanceReportTemplate', async ( data = '' , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.post('/v1/tenant/maintenanceReport-template', data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const addCustomerTicket = createAsyncThunk('ticket/addCustomerTicket', async ( newTicket = '' , { rejectWithValue }) => {

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const res = await axiosInstance.post('/v1/tenant/customer-ticket', newTicket, config)
    const ticket = res.data
    return ticket
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getTicketSiteData = createAsyncThunk('ticket/getTicketSiteData', async (id, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/ticket-site-data/${id}`)
    return  res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getAvailableIssueTags = createAsyncThunk('ticket/getAvailableIssueTags', async (_, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/getIssueTags`)
    return  res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const isTicketByCustomerAvailable = createAsyncThunk('ticket/isTicketByCustomerAvailable', async (_, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/isCustomerTicketAvailable`)

    return  res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const exportTicketData = createAsyncThunk('ticket/exportTicketData', async ({ date = '', category = '', assignedTo = '', status = '', inverter = '', search = '',issue = '', requestId = '', page = 1, sortBy = '', sortOrder = '', issuedUserType = '' } = {}, { rejectWithValue }) => {
  try {

  
    const categoryString = Array.isArray(category) ? category.join(',') : category;
    const res = await axiosInstance.get(`/v1/tenant/filteredTickets`, {
        params: { date, category: categoryString, assignedTo, status, inverter, search, issue, page, sortBy, sortOrder, issuedUserType}
      })
    const { tickets } = res.data
    return tickets
  } catch (error) {
    
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  
}
})