import { createAsyncThunk } from '@reduxjs/toolkit';
import {axiosInstance} from '../../utils/axiosInstance';



export const getComments = createAsyncThunk(
  'comment/getComments',
  async (ticket_id, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.get(`/v1/tenant/comments/${ticket_id}`)
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);

export const addComment = createAsyncThunk(
  'comment/addComment',
  async ({ ticket_id, message, userInfo }, { rejectWithValue }) => {

    try {

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const formData = {
        message,
        userInfo
      }
      const response = await axiosInstance.post(
        `/v1/tenant/comment/${ticket_id}`,
        formData,
        config
      )
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);

export const updateComment = createAsyncThunk(
  'comment/updateComment',
  async ({ comment_id, newCommentInfo }, { rejectWithValue }) => {

    try {

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const response = await axiosInstance.put(
        `/v1/tenant/comment/${comment_id}`,
        newCommentInfo,
        config
      )

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);



export const updateNameOnComments = createAsyncThunk(
  'comment/updateNameOnComments',
  async (user, { rejectWithValue }) => {

    try {

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      // Update User's name in comments database
      const response = await axiosInstance.put(
        `/v1/tenant/user/comments/${user.id}`,
        user,
        config
      )

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);

// Delete Comment
export const deleteComment = createAsyncThunk(
  'comment/deleteComment',
  async (id, { rejectWithValue }) => {

    try {

      await axiosInstance.delete(`/v1/tenant/comment/${id}`)

      return id
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
  }
);