import React from 'react';
import { useSelector } from 'react-redux';

const Skeleton = ({ width, height, borderRadius }) => {
  const theme = useSelector(state => state.theme.theme)

  const skeletonStyle = {
    width: width || '100%',
    height: height || '100%',
    backgroundColor: theme === 'dark' ? '#374156' : '#F3F3F3', // Dark mode background color and white mode background color
    borderRadius: borderRadius || '4px',
    animation: 'pulse 1.5s ease-in-out infinite'
  };

  const keyframesStyle = `
    @keyframes pulse {
      0% {
        background-color: ${theme === 'dark'  ? '#374156' : '#F3F3F3'}; // Dark mode background color and white mode background color
      }
      50% {
        background-color: ${theme === 'dark' ? '#2F374A' : '#CCCCCC'}; // Dark mode background color, slightly darker shade, and white mode border color
      }
      100% {
        background-color: ${theme === 'dark'  ? '#374156' : '#F3F3F3'}; // Dark mode background color and white mode background color
      }
    }
  `;

  return (
    <>
      <style>{keyframesStyle}</style>
      <div style={skeletonStyle}></div>
    </>
  );
};

export default Skeleton;
