import { createSlice } from '@reduxjs/toolkit';

const savedTheme = localStorage.getItem('theme') || 'dark';
const initialState = {
  theme: savedTheme, 
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      state.theme = newTheme;
      localStorage.setItem('theme', newTheme);
    },
    resetTheme: (state) => {
      state.theme = 'dark';
      localStorage.removeItem('theme');
    },
  },
});

export const { toggleTheme, resetTheme } = themeSlice.actions;
export default themeSlice.reducer;
