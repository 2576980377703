import { createAsyncThunk } from '@reduxjs/toolkit';
import {axiosInstance} from '../../utils/axiosInstance';



export const getInverterConfigs = createAsyncThunk('inverter/getInverterConfigs', async (_, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/inverterConfigs`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const addInverter = createAsyncThunk('inverter/addInverter', async ( inverter , { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axiosInstance.post(`/v1/tenant/addInverter`, inverter, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getAllInverterData = createAsyncThunk('inverter/getAllInverterData', async ({ page, sortBy, sortOrder }, { rejectWithValue }) => {
  try {
    const sortQueryString = sortBy && sortOrder ? `&sortBy=${sortBy}&sortOrder=${sortOrder}` : '';
    const res = await axiosInstance.get(`/v1/tenant/inverterData?page=${page}${sortQueryString}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateInverter = createAsyncThunk('inverter/updateInverter', async (inverter, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axiosInstance.put(`/v1/tenant/updateInverter`, inverter, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})
