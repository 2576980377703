import { createSlice } from '@reduxjs/toolkit'
import { addInverter, getAllInverterData, getInverterConfigs, updateInverter } from './inverterThunks'

const initialState = {
  inverterData: null,
  mappedInverterData: null,
  error: null,
  inverterCreatedAt: '',
  inverterDataLoading: false,
  totalInverterPages: 1,
  currentInverterData: null,
  inverterConfig: null
}


const inverterSlice = createSlice({
  name: 'inverter',
  initialState,
  reducers: {
    resetInverterState() {
      return initialState
    },
    clearCurrentInverterData(state) {
      state.currentInverterData = null
    },
    setCurrentInverterData(state, action) {
      state.currentInverterData = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getInverterConfigs.pending, (state, action) => {
        state.error = null
      })
      .addCase(getInverterConfigs.fulfilled, (state, action) => {
        state.inverterConfig = action.payload
      })
      .addCase(getInverterConfigs.rejected, (state, action) => {
        state.error = action.payload
        state.inverterDataLoading = false
      })
      .addCase(addInverter.pending, (state) => {
        state.inverterDataLoading = true;
        state.error = null
      })
      .addCase(addInverter.fulfilled, (state, action) => {
        state.inverterCreatedAt = action.payload.createdAt
        state.inverterDataLoading = false
      })
      .addCase(addInverter.rejected, (state, action) => {
        state.error = action.payload
        state.inverterDataLoading = false
      })
      .addCase(getAllInverterData.pending, (state) => {
        state.inverterDataLoading = true;
        state.error = null
      })
      .addCase(getAllInverterData.fulfilled, (state, action) => {
        state.inverterData = action.payload.result
        state.mappedInverterData = action.payload.result.map(inverter => inverter)
        state.inverterDataLoading = false
        state.totalInverterPages = action.payload.totalPages
      })
      .addCase(getAllInverterData.rejected, (state, action) => {
        state.error = action.payload
        state.inverterDataLoading = false
      })
      .addCase(updateInverter.pending, (state) => {
        state.inverterDataLoading = true;
        state.error = null
      })
      .addCase(updateInverter.fulfilled, (state, action) => {
        state.inverterDataLoading = false
        state.inverterCreatedAt = action.payload.updatedAt
      })
      .addCase(updateInverter.rejected, (state, action) => {
        state.error = action.payload
        state.inverterDataLoading = false
      })
  }
})
export const { resetInverterState, clearCurrentInverterData, setCurrentInverterData } = inverterSlice.actions

export default inverterSlice.reducer;