import { createSlice } from '@reduxjs/toolkit'
import { 
  deleteAccount, 
  getInverterFields, 
  getOtp, 
  installerSignup, 
  loadUser, 
  login, 
  register, 
  updateEmail, 
  updatePassword, 
  updateUserName, 
  verifyOtp 
} from './authThunks';


const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  authLoading: false,
  user: null,
  newUser: null,
  authError: null,
  userError: null,
  accountError: null,
  nameError: null,
  emailError: null,
  passError: null,
  nameUpdateStatus: null,
  emailUpdateStatus: null,
  accountDeleteStatus: null,
  passwordChangeStatus: null,
  userRegisterStatus: null,
  previousUrl: '/',
  registerError: null,
  otpLoading: false,
  otpLoaded: null,
  otpVerifying: false,
  otpVerified: null,
  inverterFieldsLoading: false,
  inverterFields: null,
  savingSignUpData: false,
  signUpDataSaved: null,
  userLoadingError:false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      Object.assign(state, initialState);
      state.token = null;
    },
    setPreviousUrl(state, action) {
      state.previousUrl = action.payload
    },
    resetStatus(state, action) {
      const status = action.payload
      switch (status) {
        case 'name':
          state.nameUpdateStatus = null;
          break;
        case 'email':
          state.emailUpdateStatus = null;
          break;
        case 'account':
          state.accountDeleteStatus = null;
          break;
        case 'password':
          state.passwordChangeStatus = null;
          break;
        default:
          Object.assign(state, initialState);
          state.token = null;
      }
    },
    clearUserRegisterStatus(state) {
      state.userRegisterStatus = null
    },
    clearErrors(state) {
      state.authError = null
      state.userError = null
      state.accountError = null
      state.emailError = null
      state.passError = null
      state.registerError = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUser.pending, (state) => {
        state.authLoading = true
        state.userLoadingError = false
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.isAuthenticated = true
        state.authLoading = false
        state.user = action.payload
        state.userLoadingError = false
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.authLoading = false
        state.registerError = null
        state.otpLoading = false
        state.otpVerifying = false
        state.inverterFieldsLoading = false
        state.savingSignUpData = false
        state.userLoadingError = true
      })
      .addCase(register.pending, (state) => {
        state.authLoading = true
      })
      .addCase(register.fulfilled, (state, action) => {
        state.newUser = action.payload
        state.userRegisterStatus = 'success'
        state.isAuthenticated = true
        state.authLoading = false
      })
      .addCase(register.rejected, (state, action) => {
        state.registerError = action.payload
        state.userRegisterStatus = 'failed'
        state.authLoading = false
      })
      .addCase(login.pending, (state) => {
        state.authLoading = true
        state.userLoadingError = false
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true
        state.authLoading = false
        state.userLoadingError = false
      })
      .addCase(login.rejected, (state, action) => {
        state.authError = action.payload
        state.authLoading = false
      })
      .addCase(updateEmail.pending, (state) => {
        state.authLoading = true
      })
      .addCase(updateEmail.fulfilled, (state, action) => {
        state.user = action.payload
        state.emailError = null
        state.emailUpdateStatus = 'success'
        state.authLoading = false
      })
      .addCase(updateEmail.rejected, (state, action) => {
        state.emailError = action.payload
        state.emailUpdateStatus = 'failed'
        state.authLoading = false
      })
      .addCase(updatePassword.pending, (state) => {
        state.authLoading = true
        state.passError = null
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        if(action.payload.updated === true){
          state.passwordChangeStatus = 'success'
        }else{
          state.passwordChangeStatus = 'failed'
          state.passError = action.payload.msg
        }
        state.authLoading = false
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.passError = action.payload
        state.passwordChangeStatus = 'failed'
        state.authLoading = false
      })
      .addCase(updateUserName.pending, (state) => {
        state.authLoading = true
      })
      .addCase(updateUserName.fulfilled, (state, action) => {
        state.user = action.payload
        state.nameError = action.payload
        state.nameUpdateStatus = 'success'
        state.authLoading = false
      })
      .addCase(updateUserName.rejected, (state, action) => {
        state.nameError = action.payload
        state.nameUpdateStatus = 'failed'
        state.authLoading = false
      })
      .addCase(deleteAccount.pending, (state) => {
        state.authLoading = true
      })
      .addCase(deleteAccount.fulfilled, (state) => {
        state.accountDeleteStatus = 'success'
        state.authLoading = false
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.accountError = action.payload
        state.accountDeleteStatus = 'failed'
        state.authLoading = false
      })
      .addCase(getOtp.pending, (state) => {
        state.otpLoading = true
        state.otpLoaded = null
      })
      .addCase(getOtp.fulfilled, (state, action) => {
        state.otpLoading = false
        state.otpLoaded = action.payload
      })
      .addCase(getOtp.rejected, (state) => {
        state.otpLoading = false
      })
      .addCase(verifyOtp.pending, (state) => {
        state.otpVerifying = true
        state.otpVerified = null
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.otpVerifying = false
        state.otpVerified = action.payload
      })
      .addCase(verifyOtp.rejected, (state) => {
        state.otpVerifying = false
      })
      .addCase(getInverterFields.pending, (state) => {
        state.inverterFieldsLoading = true
        state.inverterFields = null
      })
      .addCase(getInverterFields.fulfilled, (state, action) => {
        state.inverterFieldsLoading = false
        state.inverterFields = action.payload
      })
      .addCase(getInverterFields.rejected, (state) => {
        state.inverterFieldsLoading = false
      })
      .addCase(installerSignup.pending, (state) => {
        state.savingSignUpData = true
        state.signUpDataSaved = null
      })
      .addCase(installerSignup.fulfilled, (state, action) => {
        state.savingSignUpData = false
        state.signUpDataSaved = action.payload
      })
      .addCase(installerSignup.rejected, (state) => {
        state.savingSignUpData = false
      })
  }
})

export const { 
    logout, 
    setPreviousUrl, 
    resetStatus, 
    clearUserRegisterStatus, 
    clearErrors 
  } = authSlice.actions

export default authSlice.reducer