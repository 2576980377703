import { createSlice } from '@reduxjs/toolkit'
import { 
  addSiteData,
  createConfig, 
  deleteConfig, 
  exportTableToCsv, 
  getConfigData, 
  getConfirmedLayoutConfigs, 
  getConfirmedSites, 
  getConfirmedStringConfigs, 
  getMaintenanceData, 
  getNewSiteData, 
  getSiteData, 
  getStringConfigurations, 
  getWarrantiesData, 
  getWarrantyBySiteId, 
  setConfigConfirmed, 
  updateConfig, 
  updateMaintenance, 
  updateNewSite, 
  updateSite, 
  updateStringConfig, 
  updateWarrantyData,
  getTargetGenerationData,
  updateTargetGenerationData,
  updateAmcAndPaymentSchedule
 } from './siteThunks';


const initialState = {
  siteData: null,
  mappedSiteData: null,
  currentSiteData: null,
  error: null,
  totalPages: 1,
  updatedAt: '',
  siteDataLoading: false,
  configData: null,
  mappedConfigData: null,
  totalConfigPages: 1,
  configUpdatedAt: '',
  currentConfigData: null,
  configCreatedAt: '',
  configDeletedAt: '',
  configDataLoading: false,
  maintenanceData: null,
  mappedMaintenanceData: null,
  totalMaintenancePages: 1,
  maintenanceUpdatedAt: '',
  currentMaintenanceData: null,
  maintenanceCreatedAt: '',
  maintenanceDataLoading: false,
  newSiteData: null,
  mappedNewSiteData: null,
  currentNewSiteData: null,
  totalNewSitePages: 1,
  newSiteUpdatedAt: '',
  newSiteDataLoading: false,
  exportingTable: false,
  tableExported: false,
  stringConfigurationLoading: false,
  stringConfiguration: null,
  totalStringConfigurationPages: 1,
  currentStringConfigData: null,
  stringConfigUpdating: false,
  stringConfigUpdated: null,
  confirmedStringConfigs: [],
  confirmedLayoutConfigs: [],
  configConfirming: false,
  configConfirmed: null,
  sitesCount: null,
  warrantiesDataLoading: false,
  warrantiesData: null,
  singleSiteWarrantyLoading: false,
  singleSiteWarranty: null,
  updatingWarrantyData: false,
  warrantyDataUpdated: null,
  addingSite: false,
  siteAdded: null,
  totalWarrantiesPages: 1,
  currentWarrantyData: null,
  targetGenerationLoading:false,
  targetGenerationData:null,
  totalTargetGenerationPages: 1,
  currentTargetGeneration:null,
  targetGenerationUpdatedAt:'',
  updatingAmcAndPaymentSchedule:false,
  amcAndPaymentScheduleUpdated:null
};

const resetStateOnFailure = (state) => {
  state.siteDataLoading = false
  state.configDataLoading = false
  state.maintenanceDataLoading = false
  state.newSiteDataLoading = false
  state.exportingTable = false
  state.tableExported = false
  state.stringConfigurationLoading = false
  state.stringConfigUpdating = false
  state.configConfirming = false
  state.warrantiesDataLoading = false
  state.updatingWarrantyData = false
  state.addingSite = false
  state.targetGenerationLoading = false
  state.updateAmcAndPaymentSchedule = false
};


const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setCurrentSiteData(state, action) {
      state.currentSiteData = action.payload
    },
    clearCurrentSiteData(state) {
      state.currentSiteData = null
    },
    setCurrentConfigData(state, action) {
      state.currentConfigData = action.payload;
    },
    clearCurrentConfigData(state) {
      state.currentConfigData = null
    },
    setCurrentMaintenanceData(state, action) {
      state.currentMaintenanceData = action.payload
    },
    clearCurrentMaintenanceData(state) {
      state.currentMaintenanceData = null
    },
    setCurrentNewSiteData(state, action) {
      state.currentNewSiteData = action.payload
    },
    clearCurrentNewSiteData(state) {
      state.currentNewSiteData = null
    },
    resetSiteState() {
      return initialState
    },
    setCurrentStringConfigData(state, action) {
      state.currentStringConfigData = action.payload
    },
    clearCurrentStringConfigData(state) {
      state.currentStringConfigData = null
    },
    setCurrentWarrantyData(state, action) {
      state.currentWarrantyData = action.payload
    },
    clearCurrentWarrantyData(state) {
      state.currentWarrantyData = null
    },
    setCurrentTargetGenerationData(state, action) {
      state.currentTargetGeneration = action.payload;
    },
    clearCurrentTargetGenerationData(state) {
      state.currentTargetGeneration = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSiteData.pending, (state) => {
        state.siteDataLoading = true;
      })
      .addCase(getSiteData.fulfilled, (state, action) => {
        state.siteData = action.payload.result
        state.mappedSiteData = action.payload.result.map(sites => sites)
        state.totalPages = action.payload.totalPages
        state.siteDataLoading = false
      })
      .addCase(getSiteData.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(updateSite.pending, (state) => {
        state.siteDataLoading = true;
      })
      .addCase(updateSite.fulfilled, (state, action) => {
        state.updatedAt = action.payload.updatedAt
        state.siteDataLoading = false
      })
      .addCase(updateSite.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getConfigData.pending, (state) => {
        state.configDataLoading = true;
      })
      .addCase(getConfigData.fulfilled, (state, action) => {
        state.configData = action.payload.result
        state.mappedConfigData = action.payload.result.map(config => config)
        state.totalConfigPages = action.payload.totalPages
        state.configDataLoading = false
      })
      .addCase(getConfigData.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(updateConfig.pending, (state) => {
        state.configDataLoading = true;
      })
      .addCase(updateConfig.fulfilled, (state, action) => {
        state.configUpdatedAt = action.payload.updatedAt
        state.configDataLoading = false
      })
      .addCase(updateConfig.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(createConfig.pending, (state) => {
        state.configDataLoading = true;
      })
      .addCase(createConfig.fulfilled, (state, action) => {
        state.configCreatedAt = action.payload.createdAt
        state.configDataLoading = false
      })
      .addCase(createConfig.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(deleteConfig.pending, (state) => {
        state.configDataLoading = true;
      })
      .addCase(deleteConfig.fulfilled, (state, action) => {
        state.configDeletedAt = action.payload.deletedAt
        state.configDataLoading = false
      })
      .addCase(deleteConfig.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getMaintenanceData.pending, (state) => {
        state.maintenanceDataLoading = true;
      })
      .addCase(getMaintenanceData.fulfilled, (state, action) => {
        state.maintenanceData = action.payload.result
        state.mappedMaintenanceData = action.payload.result.map(maintenance => maintenance)
        state.totalMaintenancePages = action.payload.totalPages
        state.maintenanceDataLoading = false
      })
      .addCase(getMaintenanceData.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(updateMaintenance.pending, (state) => {
        state.maintenanceDataLoading = true;
      })
      .addCase(updateMaintenance.fulfilled, (state, action) => {
        state.maintenanceUpdatedAt = action.payload.updatedAt
        state.maintenanceDataLoading = false
      })
      .addCase(updateMaintenance.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getNewSiteData.pending, (state) => {
        state.newSiteDataLoading = true;
      })
      .addCase(getNewSiteData.fulfilled, (state, action) => {
        state.newSiteData = action.payload.result
        state.mappedNewSiteData = action.payload.result.map(sites => sites)
        state.totalNewSitePages = action.payload.totalPages
        state.newSiteDataLoading = false
      })
      .addCase(getNewSiteData.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(updateNewSite.pending, (state) => {
        state.newSiteDataLoading = true;
      })
      .addCase(updateNewSite.fulfilled, (state, action) => {
        state.newSiteUpdatedAt = action.payload.updatedAt
        state.newSiteDataLoading = false
      })
      .addCase(updateNewSite.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(exportTableToCsv.pending, (state) => {
        state.exportingTable = true
        state.tableExported = false
      })
      .addCase(exportTableToCsv.fulfilled, (state, action) => {
        state.exportingTable = false
        state.tableExported = true
      })
      .addCase(exportTableToCsv.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getStringConfigurations.pending, (state) => {
        state.stringConfigurationLoading = true
        state.stringConfiguration = null
      })
      .addCase(getStringConfigurations.fulfilled, (state, action) => {
        state.stringConfigurationLoading = false
        state.stringConfiguration = action.payload.result
        state.totalStringConfigurationPages = action.payload.totalPages
      })
      .addCase(getStringConfigurations.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(updateStringConfig.pending, (state) => {
        state.stringConfigUpdating = true
        state.stringConfigUpdated = null
      })
      .addCase(updateStringConfig.fulfilled, (state, action) => {
        state.stringConfigUpdating = false
        state.stringConfigUpdated = action.payload
      })
      .addCase(updateStringConfig.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(setConfigConfirmed.pending, (state) => {
        state.configConfirming = true
        state.configConfirmed = null
      })
      .addCase(setConfigConfirmed.fulfilled, (state, action) => {
        state.configConfirming = false
        state.configConfirmed = action.payload
      })
      .addCase(setConfigConfirmed.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getConfirmedStringConfigs.fulfilled, (state, action) => {
        state.confirmedStringConfigs = action.payload
      })
      .addCase(getConfirmedStringConfigs.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getConfirmedLayoutConfigs.fulfilled, (state, action) => {
        state.confirmedLayoutConfigs = action.payload
      })
      .addCase(getConfirmedLayoutConfigs.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getConfirmedSites.fulfilled, (state, action) => {
        state.sitesCount = action.payload
      })
      .addCase(getConfirmedSites.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getWarrantiesData.pending, (state) => {
        state.warrantiesDataLoading = true
        state.warrantiesData = null
      })
      .addCase(getWarrantiesData.fulfilled, (state, action) => {
        state.warrantiesDataLoading = false
        state.warrantiesData = action.payload.result
        state.totalWarrantiesPages = action.payload.totalPages
      })
      .addCase(getWarrantiesData.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getWarrantyBySiteId.pending, (state) => {
        state.singleSiteWarrantyLoading = true
        state.singleSiteWarranty = null
      })
      .addCase(getWarrantyBySiteId.fulfilled, (state, action) => {
        state.singleSiteWarrantyLoading = false
        state.singleSiteWarranty = action.payload
      })
      .addCase(getWarrantyBySiteId.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(updateWarrantyData.pending, (state) => {
        state.updatingWarrantyData = true
        state.warrantyDataUpdated = null
      })
      .addCase(updateWarrantyData.fulfilled, (state, action) => {
        state.updatingWarrantyData = false
        state.warrantyDataUpdated = action.payload
      })
      .addCase(updateWarrantyData.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(addSiteData.pending, (state) => {
        state.addingSite = true
        state.siteAdded = null
      })
      .addCase(addSiteData.fulfilled, (state, action) => {
        state.addingSite = false
        state.siteAdded = action.payload
      })
      .addCase(addSiteData.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getTargetGenerationData.pending, (state) => {
        state.targetGenerationLoading = true;
      })
      .addCase(getTargetGenerationData.fulfilled, (state, action) => {
        state.targetGenerationData = action.payload.result
        state.totalTargetGenerationPages = action.payload.totalPages
        state.targetGenerationLoading = false
      })
      .addCase(getTargetGenerationData.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(updateTargetGenerationData.pending, (state) => {
        state.targetGenerationLoading = true;
      })
      .addCase(updateTargetGenerationData.fulfilled, (state, action) => {
        state.targetGenerationUpdatedAt = action.payload.updatedAt
        state.targetGenerationLoading = false
      })
      .addCase(updateTargetGenerationData.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(updateAmcAndPaymentSchedule.pending, (state) => {
        state.updatingAmcAndPaymentSchedule = true
        state.amcAndPaymentScheduleUpdated = null
      })
      .addCase(updateAmcAndPaymentSchedule.fulfilled, (state, action) => {
        state.updatingAmcAndPaymentSchedule = false
        state.amcAndPaymentScheduleUpdated = action.payload
      })
      .addCase(updateAmcAndPaymentSchedule.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
  }
})

export const { 
  setCurrentSiteData, 
  clearCurrentSiteData, 
  setCurrentConfigData, 
  clearCurrentConfigData, 
  setCurrentMaintenanceData, 
  clearCurrentMaintenanceData, 
  resetSiteState, 
  clearCurrentNewSiteData, 
  clearCurrentStringConfigData, 
  clearCurrentWarrantyData, 
  setCurrentNewSiteData, 
  setCurrentStringConfigData, 
  setCurrentWarrantyData,
  setCurrentTargetGenerationData,
  clearCurrentTargetGenerationData 
} = siteSlice.actions
export default siteSlice.reducer
