import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

const TrendChart = ({ data }) => {
   
  const convertedData = data?.map((value, index) => ({
    name: `Data${index + 1}`,
    value: value
  }))

  return (
    <div style={{display:'flex',justifyContent:'center'}}>
      <BarChart
        width={80}
        height={80}
        data={convertedData}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5
        }}
      >
        {/* <CartesianGrid />
        <XAxis />
        <YAxis />
        <Tooltip />
        <Legend /> */}
        
        <Bar dataKey='value' fill='#8884d8' barSize={30}>
          {convertedData?.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.value >= 0 ? '#388E3C' : '#FF0000'}
            />
          ))}
        </Bar>
      </BarChart>
    </div>
  )
}

export default TrendChart
