import { createSlice } from '@reduxjs/toolkit'
import { getPreviousDate } from '../../utils/AnalyticsHelper'
import { 
  getStatusData,
  getTopIssuesData, 
  getDashboardData, 
  getLossData,  
  getInverters, 
  sendSummaryMail, 
  performanceReportData, 
  weeklyEnergyData, 
  mismatchData, 
  sendSiteInfoMail, 
  stringInverterLayoutData,
  froniusInverterLayoutData,
  uploadCSVFiles, 
  getSolarEdgeData, 
  victronLayoutData, 
  getSiteSummary, 
  getGeoSpatialData, 
  sungrowLayoutData, 
  getInverterParamsKeys, 
  getInverterGraphData, 
  getPowerGraphData, 
  getDeviceIds, 
  getHistoricalLogs, 
  getBatteryDeviceIds, 
  getUSolarDashboard, 
  getBatteryParamsKeys, 
  getUSolarSiteData, 
  getActiveGenPrGraphData, 
  getBatteryGraphData, 
  getPrVsIrradiationData, 
  getBatteryLayoutData, 
  getProjectDetails, 
  getStatesAndCities, 
  getTicketHistory, 
  generatePdfReport, 
  scheduleReport, 
  saveSitePageTemplate, 
  getSitePageTemplate, 
  getBanners, 
  saveCommLossTemplate, 
  scheduleCommLossMail, 
  getInverterLogs, 
  getTagCounts, 
  saveSiteMailTempalte, 
  savedSiteMailTempalte, 
  savedCommLossTempalte, 
  getPerformanceMonitoringData,
  getInverterErrorLogs,
  getErrorLogsDeviceIds,
  getEnphaseLogs,
  getBatteryLogs,
  getSunriseSunsetData,
  getRealTimeGraphData,
  getSiteEnergyProfileData
 } from './analyticsThunks'

const yesterday = getPreviousDate(1)

const initialState = {
  statusData: null,
  topIssuesData: null,
  filterValue: '',
  mapped: null,
  dashboardData: null,
  statesAndCities: null,
  lossData: null,
  totalPages: 1,
  currentSummaryData: null,
  error: null,
  inverters: null,
  inverterFilter: 'All',
  filterData: {
    date: yesterday,
    category: 'All',
    state: 'All',
    city: 'All'
  },
  isAscending: false,
  statusDataLoading: false,
  lossDataLoading: false,
  dashboardDataLoading: false,
  ticketOpened: false,
  topIssuesLoading: false,
  summaryMailResponse: '',
  performanceReportData: null,
  weeklyEnergyData: null,
  mismatchData: null,
  sendingSiteInfoMail: false,
  siteInfoMailResponse: '',
  graphUserSelection: null,
  stringInverterLayoutData: null,
  detectedFault: '',
  faultDetectionLoading: false,
  solarEdgeData: null,
  solarEdgeDataLoading: false,
  stringInverterDataLoading: false,
  performanceReportDataLoading: false,
  weeklyEnergyDataLoading: false,
  mismatchDataLoading: false,
  victronData: null,
  victronDataLoading: false,
  siteSummary: null,
  geoSpatialData: null,
  sungrowData: null,
  sungrowDataLoading: false,
  inverterParamsKeys: null,
  inverterParamsKeysLoading: false,
  inverterGraphDataLoading: [false],
  inverterGraphData: [],
  powerGraphDataLoading: false,
  powerGraphData: null,
  deviceIdsLoading: false,
  deviceIds: null,
  historicalLogsLoading: false,
  historicalLogs: null,
  historicalStartDate: yesterday,
  historicalEndDate: yesterday,
  uSolarDashboardDataLoading: false,
  uSolarDashboardData: null,
  uSolarDashboardBoxData: null,
  totalUsolarPages: 1,
  uSolarSiteDataLoading: false,
  uSolarSiteData: null,
  uSolarSiteBoxData: null,
  activeGenVsPrLoading: false,
  activeGenVsPrData: null,
  prVsIrradiationLoading: false,
  prVsIrradiationData: null,
  projectDetailsLoading: false,
  projectDetailsData: null,
  froniusDataLoading: false,
  froniusData: null,
  batteryDeviceIdsLoading: false,
  batteryDeviceIds: null,
  batteryParamsKeysLoading: false,
  batteryParamsKeys: null,
  batteryLayoutLoading: false,
  batteryLayout: null,
  ticketHistoryLoading: false,
  ticketHistoryData: null,
  ticketHistoryPages: 1,
  pdfData: null,
  reportScheduling: false,
  reportScheduled: false,
  savingSitePageTemplate: false,
  savedSitePageTemplate: null,
  loadingSitePageTemplate: false,
  sitePageTemplate: null,
  bannerData: null,
  commLossPreviewData: null,
  bannersLoading: false,
  commLossMailScheduling: false,
  commLossMailScheduled: null,
  savingCommLossTemplate: false,
  commLossTemplateSaved: null,
  pdfReportGenerating: false,
  description: '',
  inverterLogsLoading: false,
  inverterLogs: null,
  tagCountsLoading: false,
  tagCounts: null,
  tag: '',
  savingSiteMailTemplate: false,
  siteMailTemplateSaved: null,
  siteMailTemplateLoading: false,
  savedSiteMailTemplate: null,
  batteryGraphDataLoading: false,
  batteryGraphData: null,
  commLossTemplateLoading: false,
  savedCommLossTemplate: null,
  performanceMonitoringDataLoading: false,
  performanceMonitoringData: null,
  siteSectionUserSelections: null,
  inverterErrorLogsLoading:false,
  inverterErrorLogs: null,
  errorLogsDeviceIdsLoading: false,
  errorLogsDeviceIds: null,
  enphaseLogsLoading:false,
  enphaseLogs: null,
  totalTopIssues: 0,
  batteryLogsLoading:false,
  batteryLogs:null,
  sunriseSunsetData:{},
  realTimeGraphData:null,
  realTimeGraphDataLoading:false,
  siteEnergyData:null,
  siteEnergyDataLoading:false
}



const analyticSlice = createSlice({
  name: 'analytic',
  initialState,
  reducers: {
    updateFilter(state, action) {
      state.filterValue = action.payload
    },
    setFilterData(state, action) {
      state.filterData = action.payload
    },
    updateInverter(state, action) {
      state.inverterFilter = action.payload
    },
    setTicketOpened(state, action) {
      state.ticketOpened = action.payload
    },
    setCurrentSummaryData(state, action) {
      state.currentSummaryData = action.payload
    },
    updateGraphSelections(state, action) {
      state.graphUserSelection = action.payload
    },
    clearCurrentSummaryData(state) {
      state.currentSummaryData = null
    },
    clearReportData(state) {
      state.performanceReportData = null
    },
    clearWeeklyData(state) {
      state.weeklyEnergyData = null
    },
    clearMismatchData(state) {
      state.mismatchData = null
    },
    clearStringInverterData(state) {
      state.stringInverterLayoutData = null
    },
    clearSolarEdgeData(state) {
      state.solarEdgeData = null
    },
    clearFroniusData(state) {
      state.froniusData = null
    },
    clearVictronData(state) {
      state.victronData = null
    },
    clearSungrowData(state) {
      state.sungrowData = null
    },
    clearInverterGraphData(state) {
      state.inverterGraphData = []
    },
    clearPowerGraphData(state) {
      state.powerGraphData = null
    },
    clearDetectedFault(state) {
      state.detectedFault = ''
    },
    resetAnalyticsState() {
      return initialState
    },
    clearSitePageTemplateMessage(state) {
      state.savedSitePageTemplate = null
    },
    setCommLossPreviewData(state,action) {
      state.commLossPreviewData = action.payload
    },
    setGenerateReportLoading(state) {
      state.pdfData = null
      state.pdfReportGenerating = true
    },
    setDescription(state,action) {
      state.description = action.payload
    },
    clearDescription(state) {
      state.description = ''
    },
    setAnalyticsTagsFilter(state,action) {
      state.tag = action.payload
    },
    saveSiteSectionDataSelection(state,action) {
      state.siteSectionUserSelections = action.payload
    },
    clearSiteSectionDataSelection(state) {
      state.siteSectionUserSelections = ''
    },
    clearBatteryLogs: (state) => {
      state.batteryLogs = null;
      state.batteryLogsLoading = false;
      state.error = null;
    },
    clearInverterLogs: (state) => {
      state.inverterLogs = null;
      state.inverterLogsLoading = false;
      state.error = null;
    },
    clearErrorLogs: (state) => {
      state.inverterErrorLogs = null;
      state.inverterErrorLogsLoading = false;
      state.error = null;
    },
    clearEnphaseLogs: (state) => {
      state.enphaseLogs = null;
      state.enphaseLogsLoading = false;
      state.error = null;
    },
    clearInverterKeys: (state) => {
      state.inverterParamsKeys = null
      state.inverterParamsKeysLoading = false
    },
    clearDeviceIds: (state) => {
      state.deviceIds = null
      state.deviceIdsLoading = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatusData.pending, (state) => {
        state.statusDataLoading = true;
        state.error = null;
      })
      .addCase(getStatusData.fulfilled, (state, action) => {
        state.statusDataLoading = false;
        state.statusData = action.payload;
      })
      .addCase(getStatusData.rejected, (state, action) => {
        state.statusDataLoading = false;
        state.error = action.payload;
      })
      .addCase(getTopIssuesData.pending, (state) => {
        state.topIssuesLoading = true;
        state.error = null;
      })
      .addCase(getTopIssuesData.fulfilled, (state, action) => {
        state.topIssuesLoading = false;
        state.topIssuesData = action.payload.topIssuesData;
        state.totalPages = action.payload.totalPages
        state.mapped = action.payload.topIssuesData.map(issue => issue)
        state.totalTopIssues = action.payload.total
      })
      .addCase(getTopIssuesData.rejected, (state, action) => {
        state.topIssuesLoading = false;
        state.error = action.payload;
      })
      .addCase(getDashboardData.pending, (state) => {
        state.dashboardDataLoading = true;
        state.error = null;
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        state.dashboardDataLoading = false;
        state.dashboardData = action.payload;
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.dashboardDataLoading = false;
        state.error = action.payload;
      })
      .addCase(getStatesAndCities.fulfilled, (state, action) => {
        state.statesAndCities = action.payload;
      })
      .addCase(getStatesAndCities.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getLossData.pending, (state) => {
        state.lossDataLoading = true
        state.error = null;
      })
      .addCase(getLossData.fulfilled, (state, action) => {
        state.lossData = action.payload
        state.lossDataLoading = false
      })
      .addCase(getLossData.rejected, (state, action) => {
        state.lossDataLoading = false
        state.error = action.payload;
      })
      .addCase(getInverters.fulfilled, (state, action) => {
        state.inverters = action.payload
      })
      .addCase(getInverters.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(sendSummaryMail.fulfilled, (state, action) => {
        state.summaryMailResponse = action.payload.message
      })
      .addCase(sendSummaryMail.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(performanceReportData.pending, (state) => {
        state.performanceReportDataLoading = true
        state.error = null;
      })
      .addCase(performanceReportData.fulfilled, (state, action) => {
        state.performanceReportData = action.payload
        state.performanceReportDataLoading = false
      })
      .addCase(performanceReportData.rejected, (state, action) => {
        state.performanceReportDataLoading = false
        state.error = action.payload;
      })
      .addCase(weeklyEnergyData.pending, (state) => {
        state.weeklyEnergyDataLoading = true
        state.error = null;
      })
      .addCase(weeklyEnergyData.fulfilled, (state, action) => {
        state.weeklyEnergyData = action.payload
        state.weeklyEnergyDataLoading = false
      })
      .addCase(weeklyEnergyData.rejected, (state, action) => {
        state.error = action.payload;
        state.weeklyEnergyDataLoading = false
      })
      .addCase(mismatchData.pending, (state) => {
        state.mismatchDataLoading = true
        state.error = null;
      })
      .addCase(mismatchData.fulfilled, (state, action) => {
        state.mismatchData = action.payload
        state.mismatchDataLoading = false
      })
      .addCase(mismatchData.rejected, (state, action) => {
        state.error = action.payload;
        state.mismatchDataLoading = false
      })
      .addCase(sendSiteInfoMail.pending, (state, action) => {
        state.siteInfoMailResponse = null
        state.sendingSiteInfoMail = true
      })
      .addCase(sendSiteInfoMail.fulfilled, (state, action) => {
        state.siteInfoMailResponse = action.payload;
        state.sendingSiteInfoMail = false
      })
      .addCase(sendSiteInfoMail.rejected, (state, action) => {
        state.error = action.payload;
        state.sendingSiteInfoMail = false
      })
      .addCase(stringInverterLayoutData.pending, (state) => {
        state.stringInverterDataLoading = true
        state.error = null
      })
      .addCase(stringInverterLayoutData.fulfilled, (state, action) => {
        state.stringInverterLayoutData = action.payload
        state.stringInverterDataLoading = false
      })
      .addCase(stringInverterLayoutData.rejected, (state, action) => {
        state.error = action.payload;
        state.stringInverterDataLoading = false
      })
      .addCase(froniusInverterLayoutData.pending, (state) => {
        state.froniusDataLoading = true
        state.error = null
      })
      .addCase(froniusInverterLayoutData.fulfilled, (state, action) => {
        state.froniusData = action.payload
        state.froniusDataLoading = false
      })
      .addCase(froniusInverterLayoutData.rejected, (state, action) => {
        state.error = action.payload;
        state.froniusDataLoading = false
      })
      .addCase(uploadCSVFiles.pending, (state) => {
        state.faultDetectionLoading = true
        state.error = null
      })
      .addCase(uploadCSVFiles.fulfilled, (state, action) => {
        state.detectedFault = action.payload
        state.faultDetectionLoading = false
      })
      .addCase(uploadCSVFiles.rejected, (state, action) => {
        state.error = action.payload;
        state.faultDetectionLoading = false
      })
      .addCase(getSolarEdgeData.pending, (state) => {
        state.solarEdgeDataLoading = true
        state.error = null
      })
      .addCase(getSolarEdgeData.fulfilled, (state, action) => {
        state.solarEdgeData = action.payload
        state.solarEdgeDataLoading = false
      })
      .addCase(getSolarEdgeData.rejected, (state, action) => {
        state.error = action.payload;
        state.solarEdgeDataLoading = false
      })
      .addCase(victronLayoutData.pending, (state) => {
        state.victronDataLoading = true
        state.error = null
      })
      .addCase(victronLayoutData.fulfilled, (state, action) => {
        state.victronData = action.payload
        state.victronDataLoading = false
      })
      .addCase(victronLayoutData.rejected, (state, action) => {
        state.error = action.payload;
        state.victronDataLoading = false
      })
      .addCase(getSiteSummary.fulfilled, (state, action) => {
        state.siteSummary = action.payload
      })
      .addCase(getSiteSummary.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getGeoSpatialData.fulfilled, (state, action) => {
        state.geoSpatialData = action.payload
      })
      .addCase(getGeoSpatialData.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(sungrowLayoutData.pending, (state) => {
        state.sungrowDataLoading = true
        state.error = null
      })
      .addCase(sungrowLayoutData.fulfilled, (state, action) => {
        state.sungrowData = action.payload
        state.sungrowDataLoading = false
      })
      .addCase(sungrowLayoutData.rejected, (state, action) => {
        state.error = action.payload;
        state.sungrowDataLoading = false
      })
      .addCase(getInverterParamsKeys.pending, (state) => {
        state.inverterParamsKeysLoading = true
        state.inverterParamsKeys = null
        state.error = null
      })
      .addCase(getInverterParamsKeys.fulfilled, (state, action) => {
        state.inverterParamsKeys = action.payload
        state.inverterParamsKeysLoading = false
      })
      .addCase(getInverterParamsKeys.rejected, (state, action) => {
        state.error = action.payload;
        state.inverterParamsKeysLoading = false
      })
      .addCase(getInverterGraphData.pending, (state, action) => {
        const { index } = action.meta.arg
        const updatedLoadingData = [...state.inverterGraphDataLoading];
        updatedLoadingData[index] = true
        state.inverterGraphDataLoading = updatedLoadingData
        state.error = null
      })
      .addCase(getInverterGraphData.fulfilled, (state, action) => {
        const updatedGraphData = [...state.inverterGraphData];
        const updatedLoadingData = [...state.inverterGraphDataLoading];
        updatedGraphData[action.payload.index] = action.payload.data;
        updatedLoadingData[action.payload.index] = false;
        state.inverterGraphData = updatedGraphData
        state.inverterGraphDataLoading = updatedLoadingData
      })
      .addCase(getInverterGraphData.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getPowerGraphData.pending, (state) => {
        state.powerGraphDataLoading = true
        state.error = null
      })
      .addCase(getPowerGraphData.fulfilled, (state, action) => {
        state.powerGraphData = action.payload
        state.powerGraphDataLoading = false
      })
      .addCase(getPowerGraphData.rejected, (state, action) => {
        state.error = action.payload;
        state.powerGraphDataLoading = false
      })
      .addCase(getDeviceIds.pending, (state) => {
        state.deviceIdsLoading = true
        state.error = null
        state.deviceIds = null
      })
      .addCase(getDeviceIds.fulfilled, (state, action) => {
        state.deviceIds = action.payload
        state.deviceIdsLoading = false
      })
      .addCase(getDeviceIds.rejected, (state, action) => {
        state.error = action.payload;
        state.deviceIdsLoading = false
      })
      .addCase(getHistoricalLogs.pending, (state) => {
        state.historicalLogsLoading = true
        state.error = null
      })
      .addCase(getHistoricalLogs.fulfilled, (state, action) => {
        state.historicalLogsLoading = false
        state.historicalLogs = action.payload.devices
        state.historicalStartDate = action.payload.startDate
        state.historicalEndDate = action.payload.endDate
      })
      .addCase(getHistoricalLogs.rejected, (state, action) => {
        state.error = action.payload;
        state.historicalLogsLoading = false
      })
      .addCase(getBatteryDeviceIds.pending, (state) => {
        state.batteryDeviceIdsLoading = true
        state.error = null
        state.batteryDeviceIds = null
      })
      .addCase(getBatteryDeviceIds.fulfilled, (state, action) => {
        state.batteryDeviceIds = action.payload
        state.batteryDeviceIdsLoading = false
      })
      .addCase(getBatteryDeviceIds.rejected, (state, action) => {
        state.error = action.payload;
        state.batteryDeviceIdsLoading = false
      })
      .addCase(getUSolarDashboard.pending, (state) => {
        state.uSolarDashboardDataLoading = true
        state.error = null
      })
      .addCase(getUSolarDashboard.fulfilled, (state, action) => {
        state.uSolarDashboardDataLoading = false
        state.uSolarDashboardData = action.payload.data
        state.uSolarDashboardBoxData = action.payload.dashboardBoxData
        state.totalUsolarPages = action.payload.totalPages
      })
      .addCase(getUSolarDashboard.rejected, (state, action) => {
        state.error = action.payload;
        state.uSolarDashboardDataLoading = false
      })
      .addCase(getBatteryParamsKeys.pending, (state) => {
        state.batteryParamsKeysLoading = true
        state.error = null
      })
      .addCase(getBatteryParamsKeys.fulfilled, (state, action) => {
        state.batteryParamsKeys = action.payload
        state.batteryParamsKeysLoading = false
      })
      .addCase(getBatteryParamsKeys.rejected, (state, action) => {
        state.error = action.payload;
        state.batteryParamsKeysLoading = false
      })
      .addCase(getUSolarSiteData.pending, (state) => {
        state.uSolarSiteDataLoading = true
        state.error = null
      })
      .addCase(getUSolarSiteData.fulfilled, (state, action) => {
        state.uSolarSiteDataLoading = false
        state.uSolarSiteData = action.payload.data
        state.uSolarSiteBoxData = action.payload.boxData
      })
      .addCase(getUSolarSiteData.rejected, (state, action) => {
        state.error = action.payload;
        state.uSolarSiteDataLoading = false
      })
      .addCase(getActiveGenPrGraphData.pending, (state) => {
        state.activeGenVsPrLoading = true
        state.error = null
      })
      .addCase(getActiveGenPrGraphData.fulfilled, (state, action) => {
        state.activeGenVsPrData = action.payload
        state.activeGenVsPrLoading = false
      })
      .addCase(getActiveGenPrGraphData.rejected, (state, action) => {
        state.error = action.payload;
        state.activeGenVsPrLoading = false
      })
      .addCase(getBatteryGraphData.pending, (state) => {
        state.batteryGraphDataLoading = true
        state.batteryGraphData = null
        state.error = null
      })
      .addCase(getBatteryGraphData.fulfilled, (state, action) => {
        state.batteryGraphData = action.payload
        state.batteryGraphDataLoading = false
      })
      .addCase(getBatteryGraphData.rejected, (state, action) => {
        state.error = action.payload;
        state.batteryGraphDataLoading = false
      })
      .addCase(getPrVsIrradiationData.pending, (state) => {
        state.prVsIrradiationLoading = true
        state.error = null
      })
      .addCase(getPrVsIrradiationData.fulfilled, (state, action) => {
        state.prVsIrradiationData = action.payload
        state.prVsIrradiationLoading = false
      })
      .addCase(getPrVsIrradiationData.rejected, (state, action) => {
        state.error = action.payload;
        state.prVsIrradiationLoading = false
      })
      .addCase(getBatteryLayoutData.pending, (state) => {
        state.batteryLayoutLoading = true
        state.batteryLayout = null
        state.error = null
      })
      .addCase(getBatteryLayoutData.fulfilled, (state, action) => {
        state.batteryLayout = action.payload
        state.batteryLayoutLoading = false
      })
      .addCase(getBatteryLayoutData.rejected, (state, action) => {
        state.error = action.payload;
        state.batteryLayoutLoading = false
      })
      .addCase(getProjectDetails.pending, (state) => {
        state.projectDetailsLoading = true
        state.error = null
      })
      .addCase(getProjectDetails.fulfilled, (state, action) => {
        state.projectDetailsData = action.payload
        state.projectDetailsLoading = false
      })
      .addCase(getProjectDetails.rejected, (state, action) => {
        state.error = action.payload;
        state.projectDetailsLoading = false
      })
      .addCase(getTicketHistory.pending, (state) => {
        state.ticketHistoryLoading = true
        state.error = null
      })
      .addCase(getTicketHistory.fulfilled, (state, action) => {
        state.ticketHistoryData = action.payload.tickets
        state.ticketHistoryLoading = false
        state.ticketHistoryPages = action.payload.totalPages
      })
      .addCase(getTicketHistory.rejected, (state, action) => {
        state.error = action.payload;
        state.ticketHistoryLoading = false
      })
      .addCase(generatePdfReport.pending, (state) => {
        state.pdfReportGenerating = true
        state.pdfData = null
        state.error = null
      })
      .addCase(generatePdfReport.fulfilled, (state, action) => {
        state.pdfData = action.payload
        state.pdfReportGenerating = false
      })
      .addCase(generatePdfReport.rejected, (state, action) => {
        state.error = action.payload;
        state.pdfReportGenerating = false
      })
      .addCase(scheduleReport.pending, (state) => {
        state.reportScheduling = true
        state.error = null
      })
      .addCase(scheduleReport.fulfilled, (state, action) => {
        state.reportScheduled = action.payload
        state.reportScheduling = false
      })
      .addCase(scheduleReport.rejected, (state, action) => {
        state.error = action.payload;
        state.reportScheduling = false
      })
      .addCase(saveSitePageTemplate.pending, (state) => {
        state.savingSitePageTemplate = true
        state.error = null
      })
      .addCase(saveSitePageTemplate.fulfilled, (state, action) => {
        state.savedSitePageTemplate = action.payload.updated
        state.savingSitePageTemplate = false
      })
      .addCase(saveSitePageTemplate.rejected, (state, action) => {
        state.error = action.payload;
        state.savingSitePageTemplate = false
      })
      .addCase(getSitePageTemplate.pending, (state) => {
        state.loadingSitePageTemplate = true
        state.error = null
      })
      .addCase(getSitePageTemplate.fulfilled, (state, action) => {
        state.sitePageTemplate = action.payload.sitePageTemplate || action.payload.data
        state.loadingSitePageTemplate = false
      })
      .addCase(getSitePageTemplate.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingSitePageTemplate = false
      })
      .addCase(getBanners.pending, (state) => {
        state.bannersLoading = true
        state.error = null
      })
      .addCase(getBanners.fulfilled, (state, action) => {
        state.bannerData = action.payload
        state.bannersLoading = false
      })
      .addCase(getBanners.rejected, (state, action) => {
        state.error = action.payload;
        state.bannersLoading = false
      })
      .addCase(saveCommLossTemplate.pending, (state) => {
        state.savingCommLossTemplate = true
        state.commLossTemplateSaved = null
        state.error = null
      })
      .addCase(saveCommLossTemplate.fulfilled, (state, action) => {
        state.commLossTemplateSaved = action.payload
        state.savingCommLossTemplate = false
      })
      .addCase(saveCommLossTemplate.rejected, (state, action) => {
        state.error = action.payload;
        state.savingCommLossTemplate = false
      })
      .addCase(scheduleCommLossMail.pending, (state) => {
        state.commLossMailScheduling = true
        state.commLossMailScheduled = null
        state.error = null
      })
      .addCase(scheduleCommLossMail.fulfilled, (state, action) => {
        state.commLossMailScheduled = action.payload
        state.commLossMailScheduling = false
      })
      .addCase(scheduleCommLossMail.rejected, (state, action) => {
        state.error = action.payload;
        state.commLossMailScheduling = false
      })
      .addCase(getInverterLogs.pending, (state) => {
        state.inverterLogsLoading = true
        state.inverterLogs = null
        state.error = null
      })
      .addCase(getInverterLogs.fulfilled, (state, action) => {
        state.inverterLogs = action.payload
        state.inverterLogsLoading = false
      })
      .addCase(getInverterLogs.rejected, (state, action) => {
        state.error = action.payload;
        state.inverterLogsLoading = false
      })
      .addCase(getTagCounts.pending, (state) => {
        state.tagCountsLoading = true
        state.tagCounts = null
        state.error = null
      })
      .addCase(getTagCounts.fulfilled, (state, action) => {
        state.tagCounts = action.payload
        state.tagCountsLoading = false
      })
      .addCase(getTagCounts.rejected, (state, action) => {
        state.error = action.payload;
        state.tagCountsLoading = false
      })
      .addCase(saveSiteMailTempalte.pending, (state) => {
        state.savingSiteMailTemplate = true
        state.siteMailTemplateSaved = null
        state.error = null
      })
      .addCase(saveSiteMailTempalte.fulfilled, (state, action) => {
        state.siteMailTemplateSaved = action.payload
        state.savingSiteMailTemplate = false
      })
      .addCase(saveSiteMailTempalte.rejected, (state, action) => {
        state.error = action.payload;
        state.savingSiteMailTemplate = false
      })
      .addCase(savedSiteMailTempalte.pending, (state) => {
        state.siteMailTemplateLoading = true
        state.savedSiteMailTemplate = null
        state.error = null
      })
      .addCase(savedSiteMailTempalte.fulfilled, (state, action) => {
        state.savedSiteMailTemplate = action.payload
        state.siteMailTemplateLoading = false
      })
      .addCase(savedSiteMailTempalte.rejected, (state, action) => {
        state.error = action.payload;
        state.siteMailTemplateLoading = false
      })
      .addCase(savedCommLossTempalte.pending, (state) => {
        state.commLossTemplateLoading = true
        state.savedCommLossTemplate = null
        state.error = null
      })
      .addCase(savedCommLossTempalte.fulfilled, (state, action) => {
        state.savedCommLossTemplate = action.payload
        state.commLossTemplateLoading = false
      })
      .addCase(savedCommLossTempalte.rejected, (state, action) => {
        state.error = action.payload;
        state.commLossTemplateLoading = false
      })
      .addCase(getPerformanceMonitoringData.pending, (state) => {
        state.performanceMonitoringDataLoading = true
        state.performanceMonitoringData = null
        state.error = null
      })
      .addCase(getPerformanceMonitoringData.fulfilled, (state, action) => {
        state.performanceMonitoringData = action.payload
        state.performanceMonitoringDataLoading = false
      })
      .addCase(getPerformanceMonitoringData.rejected, (state, action) => {
        state.error = action.payload;
        state.performanceMonitoringDataLoading = false
      })
      .addCase(getInverterErrorLogs.pending, (state) => {
        state.inverterErrorLogsLoading = true
        state.inverterErrorLogs = null
        state.error = null
      })
      .addCase(getInverterErrorLogs.fulfilled, (state, action) => {
        state.inverterErrorLogs = action.payload
        state.inverterErrorLogsLoading = false
      })
      .addCase(getInverterErrorLogs.rejected, (state, action) => {
        state.error = action.payload;
        state.inverterErrorLogsLoading = false
      })
      .addCase(getErrorLogsDeviceIds.pending, (state) => {
        state.errorLogsDeviceIds = null
        state.errorLogsDeviceIdsLoading = true
        state.error = null
      })
      .addCase(getErrorLogsDeviceIds.fulfilled, (state, action) => {
        state.errorLogsDeviceIds = action.payload
        state.errorLogsDeviceIdsLoading = false
      })
      .addCase(getErrorLogsDeviceIds.rejected, (state, action) => {
        state.error = action.payload;
        state.errorLogsDeviceIdsLoading = false
      })
      .addCase(getEnphaseLogs.pending, (state) => {
        state.enphaseLogsLoading = true
        state.enphaseLogs = null
        state.error = null
      })
      .addCase(getEnphaseLogs.fulfilled, (state, action) => {
        state.enphaseLogs = action.payload
        state.enphaseLogsLoading = false
      })
      .addCase(getEnphaseLogs.rejected, (state, action) => {
        state.error = action.payload;
        state.enphaseLogsLoading = false
      })
      .addCase(getBatteryLogs.pending, (state) => {
        state.batteryLogsLoading = true
        state.batteryLogs = null
        state.error = null
      })
      .addCase(getBatteryLogs.fulfilled, (state, action) => {
        state.batteryLogs = action.payload
        state.batteryLogsLoading = false
      })
      .addCase(getBatteryLogs.rejected, (state, action) => {
        state.error = action.payload;
        state.batteryLogsLoading = false
      })
      .addCase(getSunriseSunsetData.pending, (state) => {
        state.sunriseSunsetData = {}
        state.error = null
      })
      .addCase(getSunriseSunsetData.fulfilled, (state, action) => {
        state.sunriseSunsetData = action.payload
      })
      .addCase(getSunriseSunsetData.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getRealTimeGraphData.pending, (state) => {
        state.realTimeGraphData = null
        state.realTimeGraphDataLoading = true
        state.error = null
      })
      .addCase(getRealTimeGraphData.fulfilled, (state, action) => {
        state.realTimeGraphData = action.payload
        state.realTimeGraphDataLoading = false
      })
      .addCase(getRealTimeGraphData.rejected, (state, action) => {
        state.error = action.payload;
        state.realTimeGraphDataLoading = false
      })
      .addCase(getSiteEnergyProfileData.pending, (state) => {
        state.siteEnergyData = null
        state.siteEnergyDataLoading = true
        state.error = null
      })
      .addCase(getSiteEnergyProfileData.fulfilled, (state, action) => {
        state.siteEnergyData = action.payload
        state.siteEnergyDataLoading = false
      })
      .addCase(getSiteEnergyProfileData.rejected, (state, action) => {
        state.error = action.payload;
        state.siteEnergyDataLoading = false
      })
  },
})

export const {
  updateFilter,
  setFilterData,
  updateInverter,
  setTicketOpened,
  setCurrentSummaryData,
  clearCurrentSummaryData,
  clearReportData,
  clearWeeklyData,
  clearMismatchData,
  clearStringInverterData,
  clearSolarEdgeData,
  clearFroniusData,
  clearVictronData,
  clearSungrowData,
  clearInverterGraphData,
  clearPowerGraphData,
  resetAnalyticsState,
  updateGraphSelections,
  clearSitePageTemplateMessage,
  setCommLossPreviewData,
  clearDescription,
  clearDetectedFault,
  setDescription,
  setGenerateReportLoading,
  setAnalyticsTagsFilter,
  clearSiteSectionDataSelection,
  saveSiteSectionDataSelection,
  clearBatteryLogs,
  clearInverterLogs,
  clearErrorLogs,
  clearEnphaseLogs,
  clearInverterKeys,
  clearDeviceIds
} = analyticSlice.actions

export default analyticSlice.reducer