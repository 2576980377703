import axios from 'axios';
import { logoutUser } from '../features/auth/authThunks';
import { resetUserState } from '../features/user/userSlice';
import { resetTicketState } from '../features/ticket/ticketSlice';
import { resetCommentState } from '../features/comment/commentSlice';
import { resetAnalyticsState } from '../features/analytics/analyticsSlice';
import { resetSiteState } from '../features/site/siteSlice';
import { resetInverterState } from '../features/inverter/inverterSlice';
import { resetTenantState } from '../features/tenant/tenantSlice';
import { resetTheme } from '../features/theme/themeSlice';
// import store from '../store'

const axiosInstance = axios.create({
  baseURL: '/api'
});

const setupAxiosInterceptors = (store) => {
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        store.dispatch(resetUserState())
        store.dispatch(resetTicketState())
        store.dispatch(resetCommentState())
        store.dispatch(resetAnalyticsState())
        store.dispatch(resetSiteState())
        store.dispatch(resetInverterState())
        store.dispatch(resetTenantState())
        store.dispatch(resetTheme())
        store.dispatch(logoutUser());
      }
      return Promise.reject(error);
    }
  );
};

export { axiosInstance, setupAxiosInterceptors };
