// import axios from 'axios'
import {axiosInstance} from './axiosInstance'

// const setAuthToken = (token, tenant) => {
//   if (token && tenant) {
//     axios.defaults.headers.common['x-auth-token'] = token
//     axios.defaults.headers.common['tenant'] = tenant
//   } else {
//     delete axios.defaults.headers.common['x-auth-token']
//     delete axios.defaults.headers.common['tenant']
//   }
// }

const setAuthToken = (token, tenant) => {
  if (token && tenant) {
    axiosInstance.defaults.headers.common['x-auth-token'] = token
    axiosInstance.defaults.headers.common['tenant'] = tenant
  } else {
    delete axiosInstance.defaults.headers.common['x-auth-token']
    delete axiosInstance.defaults.headers.common['tenant']
  }
}

export default setAuthToken
