import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import M from 'materialize-css/dist/js/materialize.min.js'
import { useNavigate } from 'react-router-dom'
import { faChartLine, faCog, faMap, faServer, faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import roundLogo from '../../images/SolYieldLogoRound.png'
import { BiSolidDashboard } from "react-icons/bi";
import { FaMapLocationDot } from "react-icons/fa6";
import { logoutUser } from '../../features/auth/authThunks'
import { resetCommentState } from '../../features/comment/commentSlice'
import { resetInverterState } from '../../features/inverter/inverterSlice'
import { getUserActivity } from '../../features/user/userThunks'
import { resetUserState } from '../../features/user/userSlice'
import { resetTenantState } from '../../features/tenant/tenantSlice'
import { resetSiteState } from '../../features/site/siteSlice'
import { resetAnalyticsState } from '../../features/analytics/analyticsSlice'
import { clearCurrentTicketExists, resetTicketState, setCurrentTicketExists } from '../../features/ticket/ticketSlice'
import { resetTheme, toggleTheme } from '../../features/theme/themeSlice'
import { MdLightMode, MdDarkMode } from "react-icons/md";

const Navbar = ({
  title,
  icon
}) => {
  const [currentPage, setCurrentPage] = useState(null)
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const user = useSelector((state) => state.auth.user)
  const current_ticket_exists = useSelector((state) => state.ticket.current_ticket_exists)

  let current_ticket = localStorage.getItem('currentTicket')
  let tenant = localStorage.getItem('tenant')

  useEffect(() => {
    M.AutoInit()

    setCurrentPage(window.location.pathname)

    if (!user) {
      setCurrentPage(window.location.pathname)
      onSetActive(window.location.pathname)
    }

    if (currentPage) {
      setCurrentPage(window.location.pathname)
      onSetActive(window.location.pathname)
    }

    if (current_ticket && !current_ticket_exists) {
      dispatch(setCurrentTicketExists())
    }

    // eslint-disable-next-line
  }, [user, currentPage, window.location.pathname])

  const [showSubOptions, setShowSubOptions] = useState(false)
  const subOptions = [
    { label: 'Account Settings', link: 'settings' },
    { label: 'Site Settings', link: 'site-settings' },
    { label: 'Inverter Settings', link: 'inverter-settings' },
    { label: 'Templates', link: 'templates' }
  ]
  let navigate = useNavigate()
  const handleItemClick = () => {
    // Toggle the state to show/hide sub-options
    setShowSubOptions(!showSubOptions)
  }

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleSubOptionClick = subOption => {
    setIsHovered(false)
    setIsAnalyticsHovered(false)
    navigate(`/${subOption}`)
  }

  const [showAnalyticsSubOptions, setShowAnalyticsSubOptions] = useState(false)
  let analyticsSubOptions = []

  if(tenant === 'Preview'){
  analyticsSubOptions = [
    { label: 'Summary', link: 'analytics' },
    { label: 'Sites', link: 'sites' },
    { label: 'Module Fault Detection', link: 'fault-detection' },
    // { label: 'Analytics2', link: 'analytics2' }
  ]
    } else {
    analyticsSubOptions = [
      { label: 'Summary', link: 'analytics' },
      { label: 'Sites', link: 'sites' },
      { label: 'Module Fault Detection', link: 'fault-detection' }
    ]
  }

  const handleAnalyticsItemClick = () => {
    // Toggle the state to show/hide sub-options
    setShowAnalyticsSubOptions(!showAnalyticsSubOptions)
  }

  const [isAnalyticsHovered, setIsAnalyticsHovered] = useState(false)

  const handleAnalyticsMouseEnter = () => {
    setIsAnalyticsHovered(true)
  }

  const handleAnalyticsMouseLeave = () => {
    setIsAnalyticsHovered(false)
  }

  const onSetActive = path => {
    const links = document.querySelector('ul.right').children

    let current

    switch (path) {
      case '/':
        current = links[0].innerText
        break
      case '/tickets':
        current = 'Tickets'
        break
      case '/settings':
        current = 'Settings'
        break
      case '/analytics':
        current = 'Analytics'
        break
      case '/login':
        current = 'Login'
        break
      case '/geo-spatial':
        current = 'Map'
        break
      case '/service-request':
        current = 'Service Request'
        break
      case '/system-info':
      current = 'System Informations'
        break
      // case '/register':
      //   current = 'Register'
      //   break
      default:
        current = null
    }

    let sideNavLinks
    if (!user && path === '/login') {
      sideNavLinks = document.querySelector('#sidenav-guest').children
      // Adding/Removing 'active' class from Guest SideNav Links elements
      for (let index = 0; index < sideNavLinks.length; index++) {
        if (sideNavLinks[index].innerText !== current) {
          sideNavLinks[index].classList.remove('active')
        } else {
          sideNavLinks[index].classList.add('active')
        }
      }
    }
    if (user) {
      sideNavLinks = document.querySelector('#sidenav-member').children
      // Adding/Removing 'active' class from Member SideNav Links elements
      for (let index = 0; index < sideNavLinks.length; index++) {
        if (sideNavLinks[index].innerText !== current) {
          sideNavLinks[index].classList.remove('active')
        } else {
          sideNavLinks[index].classList.add('active')
        }
      }
    }

    // Adding/Removing 'active' class from Links elements
    for (let index = 0; index < links.length; index++) {
      if (links[index].innerText !== current) {
        links[index].classList.remove('active')
      } else {
        links[index].classList.add('active')
      }
    }
  }

  const setActive = () => {
    onSetActive(window.location.pathname)
    setCurrentPage(window.location.pathname)
    if (current_ticket) {
      localStorage.removeItem('currentTicket')
      dispatch(clearCurrentTicketExists())
    }
  }

  const onCloseSideNav = e => {
    let elem
    if (e.target.tagName === 'svg') {
      elem = document.getElementById(
        e.target.parentElement.parentElement.parentElement.id
      )
    }
    if (e.target.tagName === 'path') {
      elem = document.getElementById(
        e.target.parentElement.parentElement.parentElement.parentElement.id
      )
    }

    if (e.target.tagName === 'A') {
      elem = document.getElementById(e.target.parentElement.parentElement.id)
    }
    onSetActive(window.location.pathname)
    const instance = M.Sidenav.getInstance(elem)
    instance.close()
  }

  const onLogout = () => {
    // Close Sidenav
    const instance = M.Sidenav.getInstance(
      document.querySelector('#sidenav-member')
    )
    instance.close()
    // Reset all State and Logout
    dispatch(getUserActivity({inTime:Date.now(), exitTime:Date.now(), url:'logout', logout:true}))
    dispatch(resetUserState())
    dispatch(resetTicketState())
    dispatch(resetCommentState())
    dispatch(resetAnalyticsState())
    dispatch(resetSiteState())
    dispatch(resetInverterState())
    dispatch(resetTenantState())
    dispatch(resetTheme())
    dispatch(logoutUser())
  }

  const theme = useSelector((state) => state.theme.theme);
  useEffect(() => {
    document.documentElement.classList.toggle('tw-dark', theme === 'dark');
  }, [theme]);

  const guestLinks = (
    <Fragment>
      <li onClick={setActive}>
        <Link to='/login' className='nav-links'>
          <FontAwesomeIcon
            icon='sign-in-alt'
            style={{ marginRight: '0.35em' }}
          />
          Login
        </Link>
      </li>
      {/* <li onClick={setActive}>
        <Link to='/register' className='nav-links'>
          <FontAwesomeIcon icon='user-plus' style={{ marginRight: '0.5em' }} />
          Register
        </Link>
      </li> */}
    </Fragment>
  )

  const authLinks = (
    <Fragment>
      {user && user.userType !== 'customer' && (
        <li onClick={setActive}>
          <Link to='/' className='nav-links tw-flex tw-items-center'>
            {/* <FontAwesomeIcon icon='columns' style={{ marginRight: '0.5em' }} /> */}
            
            <BiSolidDashboard className='tw-mr-2 tw-text-xl' />
            <span className='hide-on-small-only'>Dashboard</span>
          </Link>
        </li>
      )}
      {user && user.userType === 'manager' && (
        <li onClick={setActive}>
          <Link to='/geo-spatial' className='nav-links tw-flex tw-items-center'>
            {/* <FontAwesomeIcon icon={faMap} style={{ marginRight: '0.5em' }} /> */}
            <FaMapLocationDot className='tw-mr-2 tw-text-xl' />
            <span className='hide-on-small-only'>Map</span>
          </Link>
        </li>
      )}
      {user && user.userType === 'manager' && (
        <li onClick={setActive}>
          <Link to='/tickets' className='nav-links'>
            <FontAwesomeIcon
              icon={faTicketAlt}
              style={{ marginRight: '0.35em' }}
            />
            <span className='hide-on-small-only'>Tickets</span>
          </Link>
        </li>
      )}
       
      {user && user.userType === 'customer' && (
        <li onClick={setActive}>
          <Link to={user.sites.length === 1 ? `/view-site/${user.sites[0].value}` : '/analytics' } className='nav-links'>
            <FontAwesomeIcon icon={faChartLine} style={{ marginRight: '0.35em' }} />
            <span className='hide-on-small-only'>{user.sites.length === 1 ? 'Site' : 'Analytics' }</span>
          </Link>
        </li>
      )}
      {user && user.userType === 'customer' && (
        <li onClick={setActive}>
          <Link to='/system-info' className='nav-links'>
            <FontAwesomeIcon icon={faServer} style={{ marginRight: '0.35em' }} />
            <span className='hide-on-small-only'>System Informations</span>
          </Link>
        </li>
      )}
       {user && user.userType === 'customer' && (
        <li onClick={setActive}>
          <Link to='/service-request' className='nav-links'>
            <FontAwesomeIcon icon={faTicketAlt} style={{ marginRight: '0.35em' }} />
            <span className='hide-on-small-only'>Service Request</span>
          </Link>
        </li>
      )}
      {user &&
        user.userType !== 'fieldEngineer' &&
        user.userType !== 'customer' && (
          <li
            onMouseEnter={handleAnalyticsMouseEnter}
            onMouseLeave={handleAnalyticsMouseLeave}
            // onClick={handleItemClick}
            style={{ position: 'relative' }}
          >
            <Link className='nav-links'>
              <FontAwesomeIcon
                icon={faChartLine}
                style={{ marginRight: '0.35em' }}
              />
              <span className='hide-on-small-only'>Analytics</span>
            </Link>

            {isAnalyticsHovered && (
              <ul
                style={{
                  position: 'absolute',
                  top: '4.2rem',
                  left: 0,
                  zIndex: 1000,
                  backgroundColor: '#1976D2',
                  listStyleType: 'none', // Remove list bullets
                  padding: 0, // Remove default padding
                  display: 'flex',
                  flexDirection: 'column', // Display sub-options in a column
                  width: '120px'
                  // height: 'fit'
                }}
              >
                {analyticsSubOptions.map((subOption, index) => (
                  <li
                    key={index}
                    style={{
                      padding: '5px',
                      color: 'white',
                      fontSize:
                        subOption.label === 'Module Fault Detection'
                          ? '10px'
                          : 'small',
                      cursor: 'pointer',
                      borderBottom: '1px solid white',
                      lineHeight: '3'
                    }}
                    onClick={() => handleSubOptionClick(subOption.link)}
                  >
                    {subOption.label}
                  </li>
                ))}
              </ul>
            )}
          </li>
        )}
      {/* <li onClick={setActive}>
        <Link to='/settings' className='nav-links'>
          <FontAwesomeIcon icon='cog' style={{ marginRight: '0.35em' }} />
          <span className='hide-on-small-only'>Settings</span>
        </Link>
      </li> */}
      {user &&
        user.userType !== 'fieldEngineer' &&
        user.userType !== 'customer' && user.userType !== 'technician' && (
          <li
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            // onClick={handleItemClick}
            style={{ position: 'relative' }}
          >
            <Link className='nav-links'>
              <FontAwesomeIcon icon={faCog} style={{ marginRight: '0.35em' }} />
              <span className='hide-on-small-only'>Settings</span>
            </Link>

            {isHovered && (
              <ul
                style={{
                  position: 'absolute',
                  top: '4.2rem',
                  left: 0,
                  zIndex: 1000,
                  backgroundColor: '#1976D2',
                  listStyleType: 'none', // Remove list bullets
                  padding: 0, // Remove default padding
                  display: 'flex',
                  flexDirection: 'column', // Display sub-options in a column
                  width: '120px'
                  // height: 'fit'
                }}
              >
                {subOptions.map((subOption, index) => (
                  <li
                    key={index}
                    style={{
                      padding: '5px',
                      color: 'white',
                      fontSize: 'small',
                      cursor: 'pointer',
                      borderBottom: '1px solid white',
                      lineHeight: '3'
                    }}
                    onClick={() => handleSubOptionClick(subOption.link)}
                  >
                    {subOption.label}
                  </li>
                ))}
              </ul>
            )}
          </li>
        )}

      {user &&
        (user.userType === 'fieldEngineer' || user.userType === 'customer' || user.userType === 'technician') && (
          <li onClick={setActive}>
            <Link to='/settings' className='nav-links'>
              <FontAwesomeIcon
                icon={faCog}
                style={{ marginRight: '0.35em' }}
              />
              <span className='hide-on-small-only'>Settings</span>
            </Link>
          </li>
        )}
      <li onClick={setActive}>
        <a href='#!' className='nav-links' onClick={onLogout}>
          <FontAwesomeIcon
            icon='sign-out-alt'
            style={{ marginRight: '0.35em' }}
          />
          <span className='hide-on-small-only'>Logout</span>
        </a>
      </li>
    </Fragment>
  )

  const guestSideNav = (
    <ul id='sidenav-guest' className='sidenav'>
      <li>
        <div className='user-view'>
          <div className='background blue darken-2' />
          <span className='white-text name' style={{ paddingBottom: '2em' }}>
            <FontAwesomeIcon
              icon='file-invoice'
              style={{ marginRight: '1em' }}
            />
            SolarBhai
          </span>
        </div>
      </li>

      <li className='active' onClick={onCloseSideNav}>
        <Link to='/login' className='nav-links'>
          <FontAwesomeIcon
            icon='sign-in-alt'
            style={{ marginRight: '0.5em' }}
          />
          Login
        </Link>
      </li>
      {/* <li onClick={onCloseSideNav}>
        <Link to='/register' className='nav-links'>
          <FontAwesomeIcon icon='user-plus' style={{ marginRight: '0.5em' }} />
          Register
        </Link>
      </li> */}
    </ul>
  )

  const memberSideNav = (
    <Fragment>
      {user && isAuthenticated && (
        <ul id='sidenav-member' className='sidenav' style={{zIndex:1010}}>
          <li className='active'>
            <div className='user-view'>
              <div className='background blue darken-2' />
              <span className='white-text name'>
                {user.firstName} {user.lastName}
              </span>
              <span className='white-text email'>{user.email}</span>
            </div>
          </li>
          {user && user.userType !== 'customer' && (
            <li onClick={setActive}>
              <Link to='/' className='nav-links '>
                {/* <FontAwesomeIcon
                  icon='columns'
                  style={{ marginRight: '1em' }}
                /> */}
                <div className='tw-flex tw-items-center '>
                <BiSolidDashboard className='tw-mr-2 tw-text-xl' />
                <span>Dashboard</span> 
                </div>
                
              </Link>
            </li>
          )}
          {user && user.userType === 'manager'  && (
            <li onClick={setActive}>
              <Link to='/geo-spatial' className='nav-links'>
                <div className='tw-flex tw-items-center'>
                <FaMapLocationDot className='tw-mr-2 tw-text-xl' />
                <span>Map</span>
                </div>
              </Link>
            </li>
          )}
          {user && user.userType === 'manager' && (
            <li onClick={setActive}>
              <Link to='/tickets' className='nav-links'>
                <FontAwesomeIcon
                  icon={faTicketAlt}
                  style={{ marginRight: '1em' }}
                />
                Tickets
              </Link>
            </li>
          )}

          {user && user.userType === 'customer' && (
            <li onClick={setActive}>
              <Link to={user.sites.length === 1 ? `/view-site/${user.sites[0].value}` : '/analytics' } className='nav-links'>
                <FontAwesomeIcon
                  icon={faChartLine}
                  style={{ marginRight: '1em' }}
                />
                {user.sites.length === 1 ? 'Site' : 'Analytics' }
              </Link>
            </li>
          )}
          {user && user.userType === 'customer' && (
        <li onClick={setActive}>
          <Link to='/system-info' className='nav-links'>
            <FontAwesomeIcon icon={faServer} style={{ marginRight: '0.35em' }} />
            System Informations
          </Link>
        </li>
      )}
      {user && user.userType === 'customer' && (
        <li onClick={setActive}>
          <Link to='/service-request' className='nav-links'>
            <FontAwesomeIcon icon={faTicketAlt} style={{ marginRight: '0.35em' }} />
            Service Request
          </Link>
        </li>
      )}
          {user &&
            user.userType !== 'fieldEngineer' &&
            user.userType !== 'customer' && (
              <li
                // onClick={setActive}
                onClick={handleAnalyticsItemClick}
                // style={{ position: 'relative' }}
              >
                {/* <Link to='/settings' className='nav-links'> */}
                <Link className='nav-links'>
                  <FontAwesomeIcon
                    icon={faChartLine}
                    style={{ marginRight: '1em' }}
                  />
                  Analytics
                </Link>
                {/* </Link> */}
                {showAnalyticsSubOptions && (
                  <ul
                    style={{
                      // position: 'absolute',
                      // top: '4.2rem',
                      // left: 0,
                      zIndex: 1,
                      backgroundColor: 'black',
                      color: '#000000c9',
                      listStyleType: 'none', // Remove list bullets
                      padding: 0, // Remove default padding
                      display: 'flex',
                      flexDirection: 'column', // Display sub-options in a column
                      width: '100%',
                      height: 'fit',
                      textAlign: 'center'
                    }}
                  >
                    {analyticsSubOptions.map((subOption, index) => (
                      <li
                        key={index}
                        style={{
                          // padding: '5px',
                          color: 'white',
                          cursor: 'pointer',
                          fontWeight: '500',
                          borderBottom: '1px solid white',
                          fontSize: '0.9em'
                        }}
                        onClick={() => handleSubOptionClick(subOption.link)}
                      >
                        {subOption.label}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
          {user &&
            user.userType !== 'fieldEngineer' &&
            user.userType !== 'customer' && user.userType !== 'technician' && (
              <li
                // onClick={setActive}
                onClick={handleItemClick}
                // style={{ position: 'relative' }}
              >
                {/* <Link to='/settings' className='nav-links'> */}
                <Link className='nav-links'>
                  <FontAwesomeIcon icon={faCog} style={{ marginRight: '1em' }} />
                  Settings
                </Link>
                {/* </Link> */}
                {showSubOptions && (
                  <ul
                    style={{
                      // position: 'absolute',
                      // top: '4.2rem',
                      // left: 0,
                      zIndex: 1,
                      backgroundColor: 'black',
                      color: '#000000c9',
                      listStyleType: 'none', // Remove list bullets
                      padding: 0, // Remove default padding
                      display: 'flex',
                      flexDirection: 'column', // Display sub-options in a column
                      width: '100%',
                      height: 'fit',
                      textAlign: 'center'
                    }}
                  >
                    {subOptions.map((subOption, index) => (
                      <li
                        key={index}
                        style={{
                          // padding: '5px',
                          color: 'white',
                          cursor: 'pointer',
                          fontWeight: '500',
                          borderBottom: '1px solid white',
                          fontSize: '0.9em'
                        }}
                        onClick={() => handleSubOptionClick(subOption.link)}
                      >
                        {subOption.label}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}

          {user &&
            (user.userType === 'fieldEngineer' ||
              user.userType === 'customer' || user.userType === 'technician') && (
              <li onClick={setActive}>
                <Link to='/settings' className='nav-links'>
                  <FontAwesomeIcon
                    icon={faCog}
                    style={{ marginRight: '1em' }}
                  />
                  Settings
                </Link>
              </li>
            )}

          <li onClick={setActive}>
            <Link to='/login' className='nav-links' onClick={onLogout}>
              <FontAwesomeIcon
                icon='sign-out-alt'
                style={{ marginRight: '1em' }}
              />
              Logout
            </Link>
          </li>
        </ul>
      )}
    </Fragment>
  )

  return (
    <Fragment>
      <nav className='blue darken-2 tw-relative'>
      <div className='tw-top-0 tw-right-0 md:tw-top-5 md:tw-right-5 tw-fixed tw-bg-blue-500 tw-rounded-full tw-w-10 tw-h-10 tw-flex tw-justify-center tw-items-center tw-z-50' onClick={() => dispatch(toggleTheme())}>
           {theme === 'light' ? <MdDarkMode className='tw-text-lg'/> : <MdLightMode className='tw-text-lg' />} 
        </div>
        {user && isAuthenticated ? memberSideNav : guestSideNav}
        <div className='nav-wrapper container' style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between' }}>
          <Link to={user && user.userType === 'customer' ? '/analytics' : '/'} style={{ display: 'flex', alignItems: 'center',gap:'5px'}}>
            {/* <FontAwesomeIcon
              icon={icon}
              size='lg'
              style={{ marginRight: '0.5em' }}
            />{' '} */}
            <img
              src={roundLogo}
              style={{ width: '60px'}}
              alt='logo'
            />{' '}
            <span>{title}</span>
          </Link>
          <ul className='right hide-on-small-only'>
            {user && isAuthenticated ? authLinks : guestLinks}
          </ul>

          {/* Auth User SideNav Trigger */}
          <ul className='right hide-on-med-and-up'>
            <li>
              <a
                className='sidenav-trigger hide-on-med-and-up'
                href='#!'
                data-target={
                  isAuthenticated ? 'sidenav-member' : 'sidenav-guest'
                }
                onClick={() => {
                  setShowSubOptions(false)
                  setShowAnalyticsSubOptions(false)
                }}
              >
                <FontAwesomeIcon
                  icon='bars'
                  style={{ marginRight: '0.35em' }}
                />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </Fragment>
  )
}

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string
}

Navbar.defaultProps = {
  title: 'SolYield',
  icon: 'file-invoice'
}

 
export default Navbar
