import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom"
import appRouter from "./App";
import 'leaflet/dist/leaflet.css'
import store from './store'
import { Provider, useSelector } from 'react-redux'

//for dynamically providing routes based on user types
const RootComponent = () => {
  const user = useSelector(state => state.auth.user); 

  return (
    <RouterProvider router={appRouter(user?.userType)} />
  );
};

const root = createRoot(document.getElementById("root"));
root.render(<Provider store={store}><Suspense fallback={null}><RootComponent /></Suspense></Provider>);