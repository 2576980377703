import { createAsyncThunk } from '@reduxjs/toolkit';
import {axiosInstance} from '../../utils/axiosInstance';

export const addIntegration = createAsyncThunk('integrations/addIntegration', async (integration, { rejectWithValue }) => {
  try {
     
    const res = await axiosInstance.post(`/v1/tenant/addIntegration`, integration)
    return res.data

  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
});

export const getIntegrations = createAsyncThunk('integrations/getIntegrations', async (_, { rejectWithValue }) => {
  try {
     
    const res = await axiosInstance.get(`/v1/tenant/integrations`)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
});