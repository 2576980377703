import { createAsyncThunk } from '@reduxjs/toolkit';
import {axiosInstance} from '../../utils/axiosInstance';
import { downloadPDF } from '../../utils/AnalyticsHelper';

export const getSiteData = createAsyncThunk('site/getSiteData', async ({ page, sortBy, sortOrder, searchQuery }, { rejectWithValue }) => {
  try {
    const sortQueryString = sortBy && sortOrder ? `&sortBy=${sortBy}&sortOrder=${sortOrder}` : '';
    const res = await axiosInstance.get(`/v1/tenant/sitesData?page=${page}&search=${searchQuery}${sortQueryString}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateSite = createAsyncThunk('site/updateSite', async ( site , { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axiosInstance.put(`/v1/tenant/updateSite/${site.siteId}`, site, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getConfigData = createAsyncThunk('site/getConfigData', async ({ page, sortBy, sortOrder, searchQuery }, { rejectWithValue }) => {
  try {
    const sortQueryString = sortBy && sortOrder ? `&sortBy=${sortBy}&sortOrder=${sortOrder}` : '';
    const res = await axiosInstance.get(`/v1/tenant/configData?page=${page}&search=${searchQuery}${sortQueryString}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateConfig = createAsyncThunk('site/updateConfig', async (configuration, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axiosInstance.put(`/v1/tenant/updateConfig/${configuration.siteId}`, configuration, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const createConfig = createAsyncThunk('site/createConfig', async (configuration , { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axiosInstance.post(`/v1/tenant/createConfig`, configuration, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})


export const deleteConfig = createAsyncThunk('site/deleteConfig', async ( configuration , { rejectWithValue }) => {
  try {
    const res = await axiosInstance.delete(`/v1/tenant/deleteConfig?siteId=${configuration.Site_Id}&config=${configuration.config}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getMaintenanceData = createAsyncThunk('site/getMaintenanceData', async ({ page, sortBy, sortOrder, searchQuery }, { rejectWithValue }) => {
  try {
    const sortQueryString = sortBy && sortOrder ? `&sortBy=${sortBy}&sortOrder=${sortOrder}` : '';
    const res = await axiosInstance.get(`/v1/tenant/maintenanceData?page=${page}&search=${searchQuery}${sortQueryString}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateMaintenance = createAsyncThunk('site/updateMaintenance', async ( maintenanceData , { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axiosInstance.put(`/v1/tenant/updateMaintenanceData/${maintenanceData.siteId}`, maintenanceData, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getNewSiteData = createAsyncThunk('site/getNewSiteData', async ({ page, sortBy, sortOrder, searchQuery }, { rejectWithValue }) => {
  try {
    const sortQueryString = sortBy && sortOrder ? `&sortBy=${sortBy}&sortOrder=${sortOrder}` : '';
    const res = await axiosInstance.get(`/v1/tenant/newSitesData?page=${page}&search=${searchQuery}${sortQueryString}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateNewSite = createAsyncThunk('site/updateNewSite', async ( site , { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axiosInstance.put(`/v1/tenant/updateNewSite/${site.siteId}`, site, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const exportTableToCsv = createAsyncThunk('site/exportTableToCsv', async ( { table, params }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/exportTable/${table}`, { responseType: 'blob' }, { params: params || {} })
    const contentDisposition = res.headers['content-disposition'];
    let fileName = 'export.csv';
    if (contentDisposition) {
      const matches = contentDisposition.match(/filename="?([^"]+)"?/);
      if (matches.length > 1) {
        fileName = matches[1].trim().replace(/_$/, '');
      }
    }
    //named downloadpdf because it was created for downloading report pdf
    downloadPDF(res.data, fileName)
    return
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getStringConfigurations = createAsyncThunk('site/getStringConfigurations', async ({ page, sortBy, sortOrder, searchQuery }, { rejectWithValue }) => {
  try {
    const sortQueryString = sortBy && sortOrder ? `&sortBy=${sortBy}&sortOrder=${sortOrder}` : '';
    const res = await axiosInstance.get(`/v1/tenant/stringConfigData?page=${page}&search=${searchQuery}${sortQueryString}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateStringConfig = createAsyncThunk('site/updateStringConfig', async ( data , { rejectWithValue }) => {
  try {
    const res = await axiosInstance.put(`/v1/tenant/updateStringConfig/${data._id}`, data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const setConfigConfirmed = createAsyncThunk('site/setConfigConfirmed', async ( data , { rejectWithValue }) => {
  try {
    const res = await axiosInstance.put(`/v1/tenant/confirmConfig`, data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getConfirmedStringConfigs = createAsyncThunk('site/getConfirmedStringConfigs', async (_, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/confirmedStringConfigs`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getConfirmedLayoutConfigs = createAsyncThunk('site/getConfirmedLayoutConfigs', async (_, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/confirmedLayoutConfigs`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getConfirmedSites = createAsyncThunk('site/getConfirmedSites', async (_, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/confirmedSites`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getWarrantiesData = createAsyncThunk('site/getWarrantiesData', async ({page, sortBy, sortOrder, searchQuery}, { rejectWithValue }) => {
  try {
    const sortQueryString = sortBy && sortOrder ? `&sortBy=${sortBy}&sortOrder=${sortOrder}` : '';
    const res = await axiosInstance.get(`/v1/tenant/warrantiesData?page=${page}&search=${searchQuery}${sortQueryString}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})
export const getWarrantyBySiteId = createAsyncThunk('site/getWarrantyBySiteId', async (siteId, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/warranty/${siteId}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})
export const updateWarrantyData = createAsyncThunk('site/updateWarrantyData', async ({data,id}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data' 
      }
    }
    
    const res = await axiosInstance.put(`/v1/tenant/warranty/${id}`,data,config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})
export const addSiteData = createAsyncThunk('site/addSiteData', async (data, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data' 
      }
    }
    
    const res = await axiosInstance.post(`/v1/tenant/warranty`,data,config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getTargetGenerationData = createAsyncThunk('site/getTargetGenerationData', async ({ page, sortBy, sortOrder, searchQuery }, { rejectWithValue }) => {
  try {
    const sortQueryString = sortBy && sortOrder ? `&sortBy=${sortBy}&sortOrder=${sortOrder}` : '';
    const res = await axiosInstance.get(`/v1/tenant/targetGeneration?page=${page}&search=${searchQuery}${sortQueryString}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateTargetGenerationData = createAsyncThunk('site/updateTargetGenerationData', async (targetGeneration, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axiosInstance.put(`/v1/tenant/updateTargetGeneration/${targetGeneration.Site_Id}`, targetGeneration, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateAmcAndPaymentSchedule = createAsyncThunk('site/updateAmcAndPaymentSchedule', async ({data,id}, { rejectWithValue }) => {
  try { 
    
    const res = await axiosInstance.put(`/v1/tenant/updateAmcAndPaymentSchedule/${id}`,data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})