let currentAbortControllers = {};

export const getAbortController = (thunkName) => {
  // Abort the previous request if it exists
  if (currentAbortControllers[thunkName]) {
    currentAbortControllers[thunkName].abort();
  }

  // Create a new AbortController for the new request
  const newController = new AbortController();
  currentAbortControllers[thunkName] = newController;

  return newController;
};
