import { createAsyncThunk } from '@reduxjs/toolkit';
import {axiosInstance} from '../../utils/axiosInstance';



export const getTechs = createAsyncThunk('user/getTechs', async (_, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get('/v1/tenant/users/techs')
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getFEs = createAsyncThunk('user/getFEs', async (_, { rejectWithValue }) => {
  // Set Loading to True
  try {
    const res = await axiosInstance.get('/v1/tenant/users/fieldengineers')
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getUsers = createAsyncThunk('user/getUsers', async (_, { rejectWithValue }) => {
  // Set Loading to True
  try {
    const res = await axiosInstance.get('/v1/tenant/users')
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateUserData = createAsyncThunk('user/updateUserData', async ( userData, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axiosInstance.put(`/v1/tenant/user/update/${userData.userId}`, userData, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const deleteUser = createAsyncThunk('user/deleteUser', async (userData , { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        password: userData.password
      }
    }
    const res = await axiosInstance.delete(`/v1/tenant/user/delete/${userData._id}`, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getStates = createAsyncThunk('user/getStates', async (_, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/users/states`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getCities = createAsyncThunk('user/getCities', async (data, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axiosInstance.post(`/v1/tenant/users/cities`, data, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getPostalCodes = createAsyncThunk('user/getPostalCodes', async (data , { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axiosInstance.post(`/v1/tenant/users/postalCodes`, data, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getSites = createAsyncThunk('user/getSites', async (data , { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axiosInstance.post(`/v1/tenant/users/sites`, data, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getUserActivity = createAsyncThunk('user/getUserActivity', async ({ inTime, exitTime, url, logout }, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    // console.log(logout)
    let body = { inTime, exitTime, url }
    if (logout) {
      body.isLogout = logout
    }
    const res = await axiosInstance.post(`/v1/tenant/users/activity`, body, config);
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getCustomerDetails = createAsyncThunk('user/getCustomerDetails', async ({ page, search }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/users/customerDetails?page=${page}&search=${search}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateCustomerDetails = createAsyncThunk('user/updateCustomerDetails', async (data, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.put(`/v1/tenant/users/customerDetails`, data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getCustomersWithEmail = createAsyncThunk('user/getCustomersWithEmail', async (_, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/users/availableCustomers`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})


export const addCustomerDetails = createAsyncThunk('user/addCustomerDetails', async (data, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.post(`/v1/tenant/users/customerDetails`, data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})