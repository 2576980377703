import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PreLoader from '../layout/PreLoader'
import { loadUser } from '../../features/auth/authThunks'

const PrivateRoute = ({
  children
}) => {

  const dispatch = useDispatch()
  const loading = useSelector((state) => state.auth.authLoading)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const user = useSelector((state) => state.auth.user)
  const error = useSelector((state) => state.auth.userLoadingError)

  useEffect(() => {
    if (localStorage.token && !user) {
      dispatch(loadUser())
    }
  }, [])

  if (!user && localStorage.token && !error) {
    return <PreLoader />
  }
  

  if(error && localStorage.token) {
    localStorage.removeItem('token')
    localStorage.removeItem('tenant')
  }

  return !isAuthenticated && !loading   ?  <Navigate to="/login" replace /> : children 
}

export default PrivateRoute
