import { createSlice } from '@reduxjs/toolkit'
import { addComment, deleteComment, getComments, updateComment, updateNameOnComments } from './commentThunks';


const initialState = {
  comments: null,
  current_comment: null,
  edit_mode: false,
  error: null,
};

const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    setCurrent(state, action) {
      state.current_comment = action.payload
    },
    setEditMode(state) {
      state.edit_mode = !state.edit_mode
    },
    clearCurrent(state) {
      state.current_comment = null
    },
    resetCommentState() {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComments.fulfilled, (state, action) => {
        state.comments = action.payload;
      })
      .addCase(getComments.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.comments = [action.payload, ...state.comments];
      })
      .addCase(addComment.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        state.comments = state.comments.map((comment) => comment._id === action.payload._id ? action.payload : comment);
      })
      .addCase(updateComment.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateNameOnComments.fulfilled, (state, action) => {
        state.comments = action.payload;
      })
      .addCase(updateNameOnComments.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.comments = state.comments.filter((comment) => comment._id !== action.payload);
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.error = action.payload;
      })
  }
})

export const { setCurrent, clearCurrent, setEditMode, resetCommentState } = commentSlice.actions

export default commentSlice.reducer